// ----- NEWSLETTER SIGNUP FORM - IN PAGE, AND POPUP
.newsletter-signup-form {
	display: block;
	font-size: clamp(17px, 1.5vw, 24px); // 24px @ 1600px
	.signup-form-row {
		@media screen and (min-width: map-get($min-breakpoints, "medium")) {
			display: flex;
			gap: $spaceFromLine;
		}
		.signup-form-field {
			margin-bottom: 10px !important;
			border-bottom: 1px solid $primary;
			width: 100%;
			input {
				display: block;
				color: $deep-thought-blue;
				width: 100%;
				max-width: 100%;
				min-width: 100%;
				border: none;
				outline: none;
				font-size: 1em;
				padding: 0.8em 0.8em 0.6em 0;
				position: relative;
				&::placeholder {
					color: $primary;
					// font-size: 0px;
				}
				// &:focus {
				// 	&::placeholder {
				// 	}
				// }
			}
		}
	}
	.button-container {
		padding: 1em 0;
		.button {
			font-size: clamp(24px, 2.5vw, 40px); // 40px @ 1600px
			font-size: 1.666em;
		}
	}
	.disclaimer {
		font-size: clamp(14px, 2.666vw, 16px); //16px @ 600px
		input[type="checkbox"] {
			width: 1.2em;
			height: 1.2em;
			position: relative;
			top: 0.2em;
		}
		a {
			color: $primary;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
.mc4wp-form {
	.mc4wp-response {
		font-size: clamp(16px, 1.25vw, 20px); // 20px @ 1600px
		> * {
			padding-top: 1em;
		}
		.mc4wp-error {
			color: red;
		}
		.mc4wp-success {
			p {
				color: $primary;
				margin-bottom: 0;
			}
		}
	}
}

// ----- CAREERS / CV FORM
.forminator-custom-form {
	font-size: clamp(17px, 1.5vw, 24px); // 24px @ 1600px
	.forminator-row {
		margin-bottom: 10px !important;
	}
	.forminator-col {
		.forminator-field {
			position: relative;
			select,
			select * {
				outline: none !important;
			}
			input,
			textarea {
				color: $deep-thought-blue;
				width: 100%;
				max-width: 100%;
				min-width: 100%;
				border: none;
				outline: none;
				border-bottom: 1px solid $primary;
				font-size: 1em;
				// border: 1px dashed red;
			}
			input,
			textarea {
				padding: 0.8em 0.8em 0.6em 0;
				position: relative;
				&::placeholder {
					font-size: 0px;
				}
				// &:focus {
				// 	&::placeholder {
				// 	}
				// }
			}
			// textarea {
			// min-height: 40px !important;
			// }
			.button {
				outline: none;
				border: none;
				cursor: pointer;
			}
		}
		&:not(.forminator-field-upload) {
			.forminator-field {
				label {
					z-index: 1;
					position: absolute;
					top: 0.65em;
					// left: 0.65em;
					cursor: text;
					font-size: 1em;
					color: $primary;
					transition: all 0.3s ease-in-out;
				}
				&.forminator-is_active {
					label {
						top: -0.05em;
						left: 0;
						font-size: 0.5em;
					}
				}
				&.forminator-is_filled {
					label {
						top: -0.05em;
						left: 0;
						font-size: 0.5em;
					}
				}
			}
			// .forminator-response-message {
			// 	display: none !important;
			// }
			// .forminator-response-message.forminator-error {
			// 	display: none;
			// }
			.forminator-row:not(:last-child) {
			}
			.forminator-row.forminator-row-last {
				margin-left: auto !important;
				margin-right: auto !important;
			}
			.forminator-field.forminator-has_error label,
			.forminator-field.forminator-has_error .select2-selection__placeholder {
				display: none;
			}
			.forminator-error-message {
				position: absolute;
				color: #ff0000;
				left: 0;
				font-size: 0.8em;
				bottom: 0.8em;
				pointer-events: none;
			}
		}
		&.forminator-field-upload {
			.forminator-field {
				label {
					color: $primary;
				}
				input {
					color: $deep-thought-blue;
					width: auto;
					border-bottom-width: 0;
					padding-top: 0.4em;
					// color: $primary;
					font-size: 1em;
				}
				// .forminator-upload--remove,
				.forminator-error-message {
					display: none;
				}
				&.forminator-has_error {
					input {
						color: red;
					}
				}
			}
		}
	}
	.forminator-response-message {
		display: none !important;
	}
	.forminator-response-message.forminator-error {
		display: none !important;
	}
}

.forminator-field.forminator-has_error.forminator-is_active label {
	top: -0.05em;
	left: 0;
	font-size: 0.5em;
	display: block !important;
}

// select styling

.forminator-field-select {
	label {
		display: none;
	}
	.select2-container {
		width: 100% !important;
		padding: 0.8em 0.8em 0.6em 0.8em;
		// height: 47px;
		border-bottom: 1px solid $primary;
		.select2-container--default {
		}
	}
}

.select2-container--default {
	.select2-selection--single {
		.select2-selection__placeholder {
			color: $primary !important;
			font-size: 1em !important;
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			cursor: pointer;
			font-size: 1em;
			color: $primary;
			transition: all 0.3s ease-in-out;
		}
	}
}

.select2-selection__arrow {
	display: none;
}
.select2-selection__rendered {
	line-height: 1 !important;
	overflow: visible !important;
	padding: 0 !important;
	position: absolute;
	top: 0.65em;
	left: 0;
	width: 100%;
	color: $deep-thought-blue !important;
}
.select2-selection__rendered:after {
	right: 0;
	position: absolute;
	display: block;
	top: 0;
	height: 1.2em;
	content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="1.2em" fill="%232e57ed"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
}

.select2-container--open {
	.select2-selection__rendered:after {
		transform: rotate(180deg);
		top: -0.2em;
	}
}
.select2-container--default .select2-selection--single {
	border: none !important;
}

.select2-search--dropdown {
	display: none !important;
}
.select2-container--open .select2-dropdown--below {
	border: 1px solid $primary;
	border-radius: 0;
}
.select2-dropdown {
	// top: -2px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
	background-color: $primary !important;
	color: #fff !important;
}

.select2-results__options {
	// border: 1px solid $primary;
	max-height: 300px;
	font-size: clamp(17px, 1.5vw, 24px); // 24px @ 1600px
}
.select2-results__option {
	padding: 0.8em 0.8em 0.6em 0.8em !important;
	font-size: 1em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: $primary !important;
	color: #fff !important;
}

.forminator-is_active .forminator-error-message {
	display: none !important;
}

.forminator-edit-module {
	display: none;
}
