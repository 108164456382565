.switch {
	position: relative;
	display: inline-block;
	width: 180px;
	height: 42px;
	// box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
	border-radius: 3px;
	overflow: hidden;

	&__container {
		display: flex;
		align-items: center;
		width: 100%;
		min-width: 234px;
		justify-content: space-between;
		small {
			font-size: 11px;
			text-transform: uppercase;
		}
	}

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	&__toggle {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: white;
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}

	&__toggle:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 50%;
		left: 0;
		bottom: 0;
		background-color: $primary;
		-webkit-transition: 0.3s;
		transition: 0.3s;
	}

	&__label {
		position: absolute;
		top: 50%;
		left: 25%;
		transform: translate(-50%, -50%);
		color: white;
		font-family: $default-font;
		font-size: 13px;
		letter-spacing: 0.2px;
		transition: all 0.3s ease-in-out;

		&--on {
			color: white;
		}
		&--off {
			color: $primary;
			left: 75%;
		}
	}

	input:checked + .switch__toggle:before {
		-webkit-transform: translateX(100%);
		-ms-transform: translateX(100%);
		transform: translateX(100%);
	}
	input:checked + .switch__toggle .switch__label {
		&--on {
			color: $primary;
		}
		&--off {
			color: white;
		}
	}
}

.switch--buttons {
	display: flex;
	align-items: center;
	width: 100%;

	&__container {
		display: flex;
		align-items: center;
		width: 100%;
		box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
	}

	small {
		flex: 0 0 60px;
	}
	button {
		height: 42px;
		border-radius: 3px;
		overflow: hidden;
		color: $primary;
		font-family: $default-font;
		font-size: 13px;
		letter-spacing: 0.2px;
		transition: all 0.3s ease-in-out;
		text-align: center;
		justify-content: center;

		&:first-child {
			//border-right: 1px solid $primary;
			border-radius: 3px 0 0 3px;
		}
		&:last-child {
			//border-left: 1px solid $primary;
			border-radius: 0 3px 3px 0;
		}
		&.active {
			background-color: $primary;
			color: white;
		}
	}
}
