section.cta-banner {
	.container {
		padding-top: clamp(60px, 10vw, 160px); // 160px @ 1600px
		h2 {
			color: $primary;
			font-size: clamp(52px, 6.125vw, 98px); // 98px @ 1600px
			margin-bottom: 0.4em;
		}
		&--inner {
			padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
			padding-left: $spaceFromLine;
			position: relative;
			.textarea {
				// max-width: clamp(325px, 28.125vw, 450px); // 450px @ 1600px
				@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
					max-width: clamp(600px, 43.75vw, 700px); // 700px @ 1600px
				}
				p {
					font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
				}
			}
			// .button-container {
			// 	.button {
			// 		font-size: clamp(20px, 2.562vw, 41px); // 41px @ 1600px
			// 	}
			// }
		}
	}
}
