/* ---HEADER NAV--- */
.site-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 30;
	transform: translateY(0px);
	background-color: rgba(10, 19, 55, 0); // $deep-thought-blue
	transition: background-color 0.5s ease-in-out, top 0.5s ease-in-out;
	&.slide-up {
		top: -101px;
	}
}

.header-menu {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 100px;
	position: relative;
	overflow: hidden;
	// margin-left: clamp(30px, 3.75vw, 40px);
	.header-line {
		position: absolute;
		width: 2px;
		background-color: $primary;
		top: 0px;
		left: 0px;
		bottom: 0px;
		transition: all 0.5s ease-in-out;
	}
	.logo-container {
		position: relative;
		height: 57px;
		padding-left: 20px;
		// transition: height 0.3s ease-in-out;
		transform-origin: 10% 100%;
		svg {
			transform-origin: 0 0;
			transform: scale(1);
			width: auto;
			fill: $primary;
			transition: all 0.3s ease-in-out;
			transition-delay: 0.2s;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		height: 55px;
		.logo-container {
			transform: scale(0.6);
		}
	}
}

#fullPageMenu {
	.menu-logo {
		position: absolute;
		top: 0;
		left: $mobileGutter;
		left: clamp(28px, 7.179vw, 150px);
		// height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		overflow: hidden;
		transform-origin: 0 0;
		transform: scale(0.75);
		.header-line {
			position: absolute;
			width: 2px;
			background-color: white;
			top: 0px;
			left: 0px;
			bottom: 0px;
			transition: all 0.5s ease-in-out;
		}
		.logo-container {
			display: block;
			position: relative;
			height: 57px;
			margin-top: 43px;
			padding-left: 20px;
			svg {
				transform-origin: 0 0;
				transform: scale(1);
				width: auto;
				fill: white;
				transition: all 0.3s ease-in-out;
				transition-delay: 0.2s;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			transform: scale(0.5);
		}
	}
}

.site-header.scrolled {
	background-color: rgba(10, 19, 55, 0.7); // $deep-thought-blue at 70%
	background-color: $primary;
}

.full-page-menu-icon.alt-burger {
	span {
		background-color: $primary;
	}
}
.full-page-menu-icon.scrolled {
	span {
		background-color: white !important;
	}
}
html.scrolled .site-header,
.site-header.scrolled {
	.header-menu {
		.header-line {
			background-color: white;
		}
		.logo-container {
			svg {
				transform: scale(0.725);
				fill: white;
				transition-delay: 0s;
			}
		}
	}
}

/* --- FULL PAGE MENU --- */
.menu-icon-container {
	display: flex;
	justify-content: flex-end;
	position: fixed;
	top: 30px;
	right: 40px;
	right: clamp(35px, 4.166vw, 100px);
	// transform: translateY(0px);
	transition: top 0.5s ease-in-out;
	&.slide-up {
		top: -71px;
	}
	z-index: 50;
	@media screen and (min-width: 2401px) {
		right: calc(50% - 1100px);
	}
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		top: 7px;
	}
}
#mobile-menu-icon .circle-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	.full-page-menu-icon {
		transform: scale(1);
		transition: transform 0.3s ease-in-out;
	}
}
.full-page-menu-icon {
	width: 56px;
	height: 40px;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
	cursor: pointer;
	opacity: 1;
	transition: opacity 0.5s;
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		width: 30px;
	}
}

.full-page-menu-icon span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background-color: white;
	border-radius: 1.5px;
	opacity: 1;
	right: 0%;
	top: 50%;
	transition: 0.25s ease-in-out;
}

.full-page-menu-icon span:nth-child(1) {
	transform: rotate(0deg) translateY(-5px);
}

.full-page-menu-icon span:nth-child(2) {
	transform: rotate(0deg) translateY(5px);
}

.menu-icon-container.open .full-page-menu-icon {
	width: 35px;
}

.menu-icon-container.open .full-page-menu-icon span {
	width: 30px;
	background-color: white !important;
}

.menu-icon-container.open .full-page-menu-icon span:nth-child(1) {
	transform: rotate(45deg) translateY(0);
}

.menu-icon-container.open .full-page-menu-icon span:nth-child(2) {
	transform: rotate(-45deg) translateY(0);
}

.full-page-menu {
	.background-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// background-color: $fullpagemenu-background;
		background-color: rgb(10, 19, 55, 0.8);
		z-index: 40;
	}

	&__container {
		background-color: $primary;
		height: 100%;
		width: auto;
		// max-width: 960px;
		margin: 0 0 0 auto;
		// padding: 125px clamp(30px, 9.375vw, 150px) 50px clamp(30px, 9.375vw, 150px);
		// padding: 50px clamp(36px, 9.23vw, 150px); // 36px @ 390px
		padding: 50px clamp(28px, 7.179vw, 150px); // 28px @ 390px
		// padding: 50px clamp(20px, 5.128vw, 150px); // 20px @ 390px
		padding-top: clamp(80px, 10vw, 125px); // 80px @ 800px
		// padding: calc(clamp(70px, 10vw, 125px) + 25px) 3.375% 75px 3.375%
		overflow: hidden;
		overflow-y: auto;
		z-index: 41;
		&.slide-out-container {
			translate: none;
			rotate: none;
			scale: none;
			transform: translate(100%, 0px);
		}
		@media screen and (max-width: map-get($breakpoints, "small")) {
			width: 100%;
		}
	}
	.mobile-nav,
	.mobile-secondary-nav {
		// .sub-menu li {
		// 	margin-bottom: 8px;
		// }
		a {
			font-size: clamp(37px, 4.625vw, 45px); // 37px @ 800px
			color: white;
			font-weight: 400;
			line-height: 1.05em;
			text-decoration: none;
			opacity: 0;
			transition: color 0.3s ease-in-out, opacity 0.5s ease-in-out;
			&:hover {
				color: $secondary;
			}
			#fullPageMenu li a {
				text-decoration: none;
				opacity: 0;
				transition: opacity 0.5s ease-in-out;
			}
			@media screen and (min-width: map-get($min-breakpoints, "medium")) {
				br {
					display: none;
				}
			}
		}
		> li.menu-item-has-children > a {
			display: inline-flex;
			align-items: center;
			// &::after {
			// 	margin-left: 10px;
			// 	content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="%2300ffff" version="1.1" viewBox="0 0 7.4 12"><polygon points="1.4 12 0 10.6 4.6 6 0 1.4 1.4 0 7.4 6 1.4 12"/></svg>');
			// 	display: inline-block;
			// 	transform: rotate(0deg) translateX(0px) translateY(2px);
			// 	transition: all 250ms ease-in-out;
			// }
			&.opened {
				color: $secondary;
				// &::after {
				// 	margin-left: 12px;
				// 	transform: rotate(90deg) translateX(0px) translateY(0px);
				// 	transition: all 150ms ease-in-out;
				// }
			}
		}
	}
	// .header-sub-nav {
	// 	li:not(:last-child) {
	// 		margin-bottom: 8px;
	// 	}
	// 	a {
	// 		color: $primary;
	// 		font-size: 20px;
	// 		font-weight: 400;
	// 	}
	// }
}
#fullPageMenu {
	position: fixed;
	flex-direction: column;
	justify-content: flex-start;
	// justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 40;
}
#fullPageMenu.show {
	display: flex;
}

#fullPageMenu ul {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	// gap: 15px;
	gap: 0.8em;
	text-decoration: none;
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: left;
	&.mobile-secondary-nav {
		margin-top: 25px;
		gap: 10px;
		li {
			a {
				font-size: 18px;
			}
		}
	}
}

#fullPageMenu li {
	position: relative;
	// transform: translateX(-10px);
	transform: translateX(20px);
	transition: transform 0.5s ease-in-out;
	cursor: pointer;
}

// #fullPageMenu .header-nav .current-menu-item a {
// 	color: $primary;
// }

#fullPageMenu li.animate {
	transform: translateX(0);
}

#fullPageMenu li.animate a {
	opacity: 1;
}

#fullPageMenu li.animate div {
	width: 100%;
}
#fullPageMenu .full-page-menu__country {
	// margin-top: 25px;
	margin-bottom: 25px;
	display: flex;
	justify-content: flex-end;
	transform: translateX(10px);
	transition: all 0.7s ease-in-out;
	opacity: 0;
	p {
		color: red;
	}
}
#fullPageMenu .full-page-menu__button {
	margin-top: 25px;
	display: flex;
	justify-content: flex-start;
	transform: translateX(-10px);
	transition: all 0.7s ease-in-out;
	opacity: 0;
	.block-button {
		padding: 0.4em 0.8em;
	}
}
#fullPageMenu .full-page-menu__country.animate,
#fullPageMenu .full-page-menu__button.animate {
	transform: translateX(0);
	opacity: 1;
}

// @media screen and (max-width: 1250px) {
#fullPageMenu .sub-menu {
	gap: 0;
	font-size: clamp(17px, 2.125vw, 40px); // 17px @ 800px
	width: 100%;
	position: relative;
	visibility: visible;
	opacity: 1;
	background-color: transparent;
	transform: none;
	top: 0;
	// left: 10px;
	// max-height: 0;
	overflow: hidden;
	// margin-top: -5px;
	// padding-bottom: 10px;
	transition: height 0.35s ease-out;
	transition: height 0.5s cubic-bezier(0.25, 1, 0.5, 1);
	&.collapsed {
		height: 0 !important;
	}
	a {
		color: $secondary;
	}
}
// #fullPageMenu .sub-menu.expand {
// 	max-height: 120px;
// }
#fullPageMenu .sub-menu .menu-item:first-child {
	margin-top: 0.3em;
}
#fullPageMenu .sub-menu .menu-item {
	width: 100%;
	opacity: 1;
	transform: none;
	height: 20px;
	height: 1.3em;
	padding-left: 0.5em;
	border-left: 1px solid white;
}
#fullPageMenu .sub-menu .menu-item a {
	display: inline-flex;
	align-items: center;
	font-size: 20px;
	font-size: 1em;
	gap: 0.3em;
	white-space: nowrap;
	&::after {
		content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%2300ffff" version="1.1" viewBox="0 0 20.7 13.5"><polygon points="13.9 0 12.5 1.4 16.8 5.8 0 5.8 0 7.8 16.8 7.8 12.5 12.1 13.9 13.5 20.7 6.8 13.9 0"/></svg>');
		display: inline-block;
		font-size: inherit;
		width: 0.8em;
	}
}
// }
