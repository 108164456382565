/* ---BUTTON CONTAINER--- */
.button-container {
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
}

.button {
	white-space: nowrap;
	color: $primary;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	transition: color 0.2s ease-in-out, font-size 0s;
	font-size: clamp(16px, 4.102vw, 20px); // 16px @ 390px
	font-family: $default-font;
	font-weight: 400;
	&--lg {
		font-size: clamp(16px, 4.102vw, 30px); // 16px @ 390px
	}
	svg {
		height: 0.65em;
		display: inline-block;
		margin-left: 0.4em;
		fill: $deep-thought-blue;
		transform: translateY(0.08em);
		transition: all 0.2s ease-in-out;
	}
	&:hover,
	&:focus {
		color: $deep-thought-blue;
		// svg {
		// 	fill: $deep-thought-blue;
		// }
	}
}

.button.solid-button {
	font-size: clamp(18px, 3vw, 20px); // 18px @ 600px
	border: 2px solid $secondary;
	border-radius: 5px;
	padding: 0.3em 0.8em;
	span {
		color: white;
	}
	&.primary-cta {
		background-color: $secondary;
		span {
			color: $deep-thought-blue;
		}
	}
}

.button.slide-button {
	color: $primary;
	font-weight: 700;
	span {
		background-image: linear-gradient(
			to right,
			$deep-thought-blue,
			$deep-thought-blue 50%,
			$primary 50%
		);
		background-size: 200% 1em;
		background-position: 100%;
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: background-position 0.3s ease-in-out;
	}
	svg {
		fill: $primary;
	}
	&:hover,
	&:focus {
		color: $deep-thought-blue;
		span {
			background-position: 0;
		}
	}
	&.primary {
		color: $primary;
		span {
			background-image: linear-gradient(
				to right,
				$deep-thought-blue,
				$deep-thought-blue 50%,
				$primary 50%
			);
		}
		svg {
			fill: $deep-thought-blue;
		}
		&:hover,
		&:focus {
			color: $deep-thought-blue;
		}
	}
	&.secondary {
		color: $secondary;
		span {
			background-image: linear-gradient(to right, white, white 50%, $secondary 50%);
		}
		svg {
			fill: $secondary;
		}
		&:hover,
		&:focus {
			color: white;
		}
	}
	&.white {
		color: white;
		span {
			background-image: linear-gradient(to right, $secondary, $secondary 50%, white 50%);
		}
		svg {
			fill: $secondary;
		}
		&:hover,
		&:focus {
			color: $secondary;
		}
	}
	&.slide-left {
		flex-direction: row-reverse;
		span {
			background-image: linear-gradient(
				to left,
				$deep-thought-blue,
				$deep-thought-blue 50%,
				$primary 50%
			);
			background-position: 0%;
		}
		svg {
			transform: scaleX(-1);
			margin: 0 0.4em 0 0;
		}
		&:hover,
		&:focus {
			span {
				background-position: 100%;
			}
		}
		&.primary {
			span {
				background-image: linear-gradient(
					to left,
					$deep-thought-blue,
					$deep-thought-blue 50%,
					$primary 50%
				);
			}
		}
		&.secondary {
			span {
				background-image: linear-gradient(to left, white, white 50%, $secondary 50%);
			}
		}
		&.white {
			span {
				background-image: linear-gradient(to left, $secondary, $secondary 50%, white 50%);
			}
		}
	}
}
