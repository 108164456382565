body {
	font-family: $default-font;
	font-size: $global-font-size;
	line-height: $global-lineheight;
	font-weight: $global-weight;
	color: $paragraph-colour;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
	color: $header-colour;
	font-family: $header-large-font;
	// letter-spacing: -0.02em;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
	color: $header-colour;
	font-family: $header-small-font;
	// letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;
}

@each $size, $headers in $header-styles {
	@include breakpoint($size) {
		@each $header, $header-defs in $headers {
			#{$header},
			.#{$header} {
				@if map-has-key($header-defs, font-size) {
					font-size: map-get($header-defs, font-size);
					margin-bottom: map-get($header-defs, margin-bottom);
					line-height: map-get($header-defs, line-height);
					font-weight: map-get($header-defs, font-weight);
					font-family: map-get($header-defs, font-family);
				}
			}
		}
	}
}

p {
	font-family: $default-font;
	font-weight: $global-weight;
	margin-bottom: $paragraph-margin-bottom;
}

a {
	text-decoration: none;
	// transition: all 0.25s ease-in-out;
}
p {
	a {
		color: $paragraph-anchor-colour;
	}
}
a:hover {
	color: $anchor-hover-colour;
}

sup {
	top: -0.85em;
	font-size: 50%;
}

.symbol {
	font-size: 109%;
}
