section.testimonials-carousel {
	margin-top: 0;
	padding: clamp(60px, 7.5vw, 120px) 0 clamp(80px, 10vw, 160px) 0; // VW @ 1600px
	position: relative;
	.container {
		position: relative;
		z-index: 2;
		background-color: white;
		max-width: 1400px;
		padding: $spaceFromLine 0;
		.client-quotes {
			.client-quote {
				padding: 0 clamp(75px, 9.375vw, 150px); // 150px @ 1600px;
				margin: 0 auto;
				container-type: inline-size; // cqw @ 1100px
				.client-details {
					color: $deep-thought-blue;
					text-align: center;
					font-size: clamp(25px, 3.454cqw, 38px); // 38px @ 1100px
					font-weight: 400;
					margin: 0.4em auto 0 auto;
				}
				.client-testimonial {
					position: relative;
					text-align: center;
					font-size: clamp(25px, 5.454cqw, 60px); // 60px @ 1100px
					line-height: 1.1em;
					font-weight: 400;
					color: $primary;
					margin: 0.2em auto 0 auto;
				}
			}
		}
		.swiper-pagination-bullets {
			text-align: center;
			margin: 0;
			.swiper-pagination-bullet {
				background-color: $primary;
				opacity: 1;
				width: 12px;
				height: 12px;
				&:first-child {
					margin-left: 0;
				}
			}
			.swiper-pagination-bullet-active {
				background-color: $deep-thought-blue;
				opacity: 1;
			}
		}
		.swiper-arrows {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
			pointer-events: none;
			z-index: 3;
			.swiper-prev-testimonials,
			.swiper-next-testimonials {
				pointer-events: all;
				font-size: clamp(25px, 3.125vw, 50px); // 50px @ 1600px
				svg {
					fill: $primary;
					transition: fill 0.3s ease-in-out;
				}
				&:hover {
					svg {
						fill: $deep-thought-blue;
					}
				}
			}
			.swiper-prev-testimonials {
				transform: scaleX(-1);
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.swiper-arrows {
				display: none;
			}
			.client-quotes {
				.client-quote {
					padding: 0;
				}
			}
		}
	}
	.line-track {
		&.line-one {
			left: 50%;
			z-index: 1;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "medium")) {
		.container {
			.client-quotes {
				.client-quote {
					.client-details {
						padding-bottom: 0.4em;
						span {
							display: block;
							text-align: center;
						}
						.divider {
							display: none;
						}
					}
				}
			}
		}
	}
}
