.resources-form-overlay {
	background-color: #0a1337d9;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 30px;
	padding: 0 clamp(15px, 3.846vw, 100px); // 15px @ 390px
	display: flex;
	align-items: center;
	justify-content: center;
	// z-index: 20;
	z-index: 200;
	.resources-form-overlay-popup {
		background-color: white;
		width: 100%;
		// padding: clamp(30px, 7.692vw, 60px) clamp(15px, 3.846vw, 120px); // 30px @ 390px
		padding: clamp(30px, 7.692vw, 60px) clamp(20px, 5.128vw, 40px); // 20/30px @ 390px
		max-width: 860px;
		.resources-form-overlay-content {
			padding: 0 clamp(15px, 3.846vw, 100px); // 15px @ 390px
			// max-height: 80vh;
			max-height: 70vh;
			overflow: hidden;
			overflow-y: auto;
			h2 {
				font-size: clamp(38px, 3.75vw, 60px); // 60px @ 1600px
			}
		}
	}
}
