section.the-brief {
	margin: 0;
	background-color: $deep-thought-blue;
	.container {
		position: relative;
		.case-study-columns {
			display: flex;
			flex-direction: row-reverse;
			padding-bottom: clamp(20px, 2.5vw, 40px); // 40px @ 1600px
			// padding-bottom: clamp(60px, 7.5vw, 120px); // 80px @ 1600px
			.images-column {
				width: 50%;
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
				.swiper {
					background-color: $deep-thought-blue;
				}
			}
			.content-column {
				width: 50%;
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
				padding-left: $spaceFromLine;
			}
			h2 {
				color: $secondary;
				font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
				margin: 0 0 0.4em 0;
			}
			.wysiwyg {
				ul,
				ol {
					padding-inline-start: 1.4em;
				}
				p,
				li {
					color: white;
					font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
				}
				> *:last-child {
					margin-bottom: 0;
				}
				li + li {
					margin-top: 0.75em;
				}
			}
			.image-wrapper {
				width: 100%;
				height: auto;
				aspect-ratio: 1.6;
				position: relative;
				.img-center {
					object-fit: cover;
				}
			}
		}
		.swiper-case-study {
			.swiper-wrapper {
				position: relative;
				z-index: 1;
			}
		}
		.swiper-pagination-bullets {
			position: absolute;
			bottom: clamp(15px, 1.875vw, 30px); // 30px @ 1600px
			text-align: center;
			margin: 0;
			z-index: 2;
			.swiper-pagination-bullet {
				background-color: white;
				opacity: 0.3;
				width: 12px;
				height: 12px;
				&:first-child {
					margin-left: 0;
				}
			}
			.swiper-pagination-bullet-active {
				background-color: white;
				opacity: 1;
			}
		}
		.swiper-arrows {
			position: absolute;
			top: 0;
			right: 1em;
			bottom: 0;
			left: 1em;
			display: flex;
			align-items: center;
			justify-content: space-between;
			pointer-events: none;
			z-index: 3;
			font-size: clamp(20px, 2.187vw, 35px); // 50px @ 1600px
			svg {
				fill: white;
			}
			.swiper-prev-case-study,
			.swiper-next-case-study {
				pointer-events: all;
				font-size: inherit;
			}
			.swiper-prev-case-study {
				transform: scaleX(-1);
			}
		}
		.line-track {
			&.line-mobile {
				display: none;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.line-track {
				&.line-mobile {
					display: block;
				}
			}
			.case-study-columns {
				display: block;
				padding-bottom: 0;
				.images-column {
					width: auto;
					margin: 0 calc(0px - $mobileGutter);
				}
				.content-column {
					width: auto;
				}
			}
		}
	}
}

section.the-solution {
	margin: 0;
	background-color: $deep-thought-blue;
	.container {
		position: relative;
		.case-study-columns {
			display: flex;
			// flex-direction: row-reverse;
			.content-column,
			.feature-column {
				width: 50%;
			}
			.content-column {
				position: relative;
				padding-left: $spaceFromLine;
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
				// padding-bottom: clamp(20px, 2.5vw, 40px); // 80px @ 1600px
			}
			.feature-column {
				padding-right: $spaceFromLine;
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
			}
			h2 {
				color: $secondary;
				font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
				margin: 0 0 0.4em 0;
			}
			.feature-text {
				color: $secondary;
				font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
			}
			.wysiwyg {
				ul,
				ol {
					padding-inline-start: 1.4em;
				}
				p,
				li {
					color: white;
					font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
				}
				> *:last-child {
					margin-bottom: 0;
				}
				li + li {
					margin-top: 0.75em;
				}
			}
		}
		.line-track {
			&.line-mobile {
				display: none;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.line-track {
				&.line-desktop {
					display: none;
				}
				&.line-mobile {
					display: block;
				}
			}
			.case-study-columns {
				display: block;
				.content-column,
				.feature-column {
					width: auto;
					padding-left: $spaceFromLine;
				}
			}
		}
	}
}

section.the-results {
	margin: 0;
	background-color: $deep-thought-blue;
	.container {
		position: relative;
		.case-study-columns {
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;
			.content-column {
				width: 50%;
				padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
				// padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
			}
			.infographic-column {
				width: 50%;
			}
			.content-column {
				position: relative;
				padding-left: $spaceFromLine;
			}
			.infographic-column {
				padding-left: $spaceFromLine;
			}
			h2,
			.feature-text {
				color: $secondary;
				font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
				margin: 0 0 0.4em 0;
			}
			.wysiwyg {
				ul,
				ol {
					padding-inline-start: 1.4em;
				}
				p,
				li {
					color: white;
					font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
				}
				> *:last-child {
					margin-bottom: 0;
				}
				li + li {
					margin-top: 0.75em;
				}
			}
			.results-amount {
				position: relative;
				color: $secondary;
				font-size: clamp(100px, 14.0625vw, 225px); // 45px @ 1600px
				margin: 0.1em 0 0 0;
				font-weight: 400;
				line-height: 1em;
				top: 0.075em;
			}
			&.has-infographic {
				@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
					flex-direction: row;
					.content-column {
						padding-left: 0;
						padding-right: $spaceFromLine;
					}
					.infographic-column {
						padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
					}
				}
				@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
					.infographic-column {
						padding-bottom: clamp(60px, 10vw, 160px); // 160px @ 1600px
						// padding-left: 0;
						// margin: 0 calc(0px - $mobileGutter);
					}
				}
			}
		}
		.line-track {
			&.line-both {
				left: 50%;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.line-track {
				&.line-both {
					left: 0;
				}
			}
			.case-study-columns {
				display: block;
				.content-column,
				.infographic-column {
					width: auto;
					padding-left: $spaceFromLine;
				}
			}
		}
	}
}
