/* ----FOOTER---- */

.footer {
	background-color: $footer-background;
	position: relative;
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		background-image: none !important;
	}
	overflow: hidden;
	.container {
		position: relative;
		z-index: 1;
	}
	.footer-logo {
		margin-top: 90px;
		position: relative;
		&-container {
			width: 242px;
			max-width: 46.153vw; // 180px @ 390px
			img {
				display: block;
				svg {
					fill: white;
				}
			}
		}
	}
	.footer-main {
		position: relative;
		padding-top: 30px;
		width: 100%;
		display: grid;
		grid-template: 1fr / auto repeat(3, 1fr);
		// display: flex;
		// justify-content: flex-start;
		align-items: stretch;
		// padding-bottom: 60px;
		.footer-strapline {
			width: min-content;
			font-family: StraplineFont, $default-font;
			font-size: 68px;
			// font-size: clamp(35px, 8.974vw, 68px); // 35px @ 390px
			font-size: clamp(35px, 4.375vw, 58px); // 35px @ 800px
			color: white;
			margin: 0;
			font-weight: 400;
			line-height: 0.9em;
			padding-right: 1.2em;
			padding-right: clamp(65px, 6.25vw, 100px); // 100px @ 1600px
		}
	}
	p:not(.footer-strapline) {
		line-height: 1.34;
		margin-bottom: 0;
	}
	.partner-logos {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		gap: 20px;
		margin-bottom: 30px;
		img {
			width: auto;
			height: auto;
			&.mrs-logo {
				height: 77px;
			}
			&.bcorp-logo {
				height: 90px;
			}
		}
	}
	.footer-menu {
		// width: clamp(100px, 25vw, 400px);
		position: relative;
		border-left: 1px solid white;
		// container-type: inline-size; // cqw @ 400px
		ul {
			// padding-left: 35px;
			padding: 0 2.187vw; // 35px @ 1600px
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			li {
				font-size: 22px;
				font-size: clamp(22px, 1.625vw, 26px); // 26px @ 1600px
				line-height: 1.1em;
				padding: 0 0 0.3em 0;
				a {
					color: #fff;
					@media screen and (min-width: map-get($min-breakpoints, "xlarge")) {
						white-space: nowrap;
					}
					font-size: inherit;
					font-weight: 400;
					display: inline-block;
					transition: color 0.2s ease-in-out;
					padding: 0;
					position: relative;
					// top: 0.05em;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
	@media screen and (min-width: map-get($min-breakpoints, "large")) {
		.line-track {
			display: none;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "large")) {
		.footer-main {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}
		.footer-logo,
		.footer-main {
			// padding-left: 20px;
			// border-left: 1px solid white;
			padding-left: $spaceFromLine;
		}
		.footer-menu {
			width: auto;
			border-left-width: 0;
			ul {
				padding-left: 0;
				li {
					padding: 0.2em 0 0 0;
				}
			}
		}
		.partner-logos {
			justify-content: center;
			margin: 40px 0;
		}
	}
}
.socket {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-bottom: 25px;
	gap: 10px;
	font-size: 14px;
	font-size: clamp(14px, 0.875vw, 16px); // 14px @ 1600px
	@media screen and (max-width: map-get($breakpoints, "large")) {
		flex-direction: column;
	}
	a {
		color: $footer-socket-anchor;
		font-size: inherit;
		transition: color 0.2s ease-in-out;
		&:hover {
			color: $footer-socket-anchor-hover;
		}
	}
	p {
		color: $footer-socket-anchor;
		display: inline-block;
		font-size: inherit;
		// line-height: 1em;
		text-align: center;
		width: auto;
	}
	.socket-copyright {
		display: flex;
		align-items: center;
		font-size: inherit;
		min-height: 18px;
		p {
			font-size: inherit;
			line-height: 1.3;
		}
		@media screen and (min-width: map-get($min-breakpoints, "large")) {
			&::after {
				content: "";
				display: inline-block;
				width: 1px;
				height: 1em;
				background-color: $footer-socket-anchor;
				margin-left: 10px;
			}
		}
	}
	.socket-navigation {
		font-size: inherit;
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			align-items: center;
			font-size: inherit;
			gap: 10px;
			min-height: 18px;
			li {
				display: inline-flex;
				align-items: center;
				margin: 0;
				padding: 0;
				font-size: inherit;
			}
			@media screen and (min-width: map-get($min-breakpoints, "large")) {
				li {
					&::after {
						content: "";
						display: inline-block;
						width: 1px;
						height: 1em;
						background-color: $footer-socket-anchor;
						margin-left: 10px;
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "large")) {
				li:not(:last-child) {
					&::after {
						content: "";
						display: inline-block;
						width: 1px;
						height: 1em;
						background-color: $footer-socket-anchor;
						margin-left: 10px;
					}
				}
			}
		}
	}
	.socket-website {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
// @media screen and (min-width: map-get($min-breakpoints, "large")) {
.anim-footer-strapline,
.anim-footer-logo,
.anim-footer-menu-one,
.anim-footer-menu-two,
.anim-footer-menu-three,
.anim-partner-logos,
.anim-socket {
	opacity: 0;
}
// }
