section.sub-service-info {
	margin-top: 0;
	background-color: $deep-thought-blue;
	.container {
		&--inner {
			.heading-wrapper {
				&--inner {
					h3 {
						font-size: clamp(25px, 2.345vw, 40px); // 45px @ 1920px
						color: $secondary;
						margin: 0;
					}
					h2 {
						font-size: clamp(38px, 3.75vw, 60px); // 60px @ 1600px;
						line-height: 1em;
						color: white;
						font-weight: 400;
						margin: 0;
					}
				}
			}
			.copy-wrapper {
				.wysiwyg {
					overflow: hidden;
					p {
						font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
						color: white;
						&:first-child {
							margin-top: 0.3em;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
					a {
						color: $secondary;
						// display: block;
						// overflow: hidden;
						// text-overflow: ellipsis;
						word-wrap: break-word;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "large")) {
			position: relative;
			padding: clamp(80px, 10vw, 160px) 0; // 160px @ 1600px
			padding-left: $spaceFromLine;
			&--inner {
				.heading-wrapper {
					&--inner {
						h3 {
							margin: 0 0 60px 0;
						}
						h2 {
							margin: 0 0 60px 0;
						}
					}
				}
			}
		}
	}
	.line-track {
		&.line-one {
			display: none;
		}
		&.line-two {
			display: none;
		}
	}
}
@media screen and (min-width: map-get($min-breakpoints, "large")) {
	section.sub-service-info {
		.container {
			&--inner {
				display: grid;
				grid-template: auto 1fr / 1fr 50%; // VW @ 1600px;
				align-items: start;
				.heading-wrapper {
					position: relative;
					margin-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
					padding: clamp(120px, 15vw, 240px) clamp(40px, 5vw, 80px) 0 0; // VW @ 1600px
					&--inner {
						position: relative;
						h3 {
							position: absolute;
							bottom: 100%;
							left: 0;
							padding-bottom: 1em;
						}
					}
				}
				.copy-wrapper {
					position: relative;
					padding: clamp(120px, 15vw, 240px) 0 clamp(80px, 10vw, 160px) clamp(40px, 5vw, 80px); // VW @ 1600px
					align-self: stretch;
				}
			}
		}
		.line-track {
			&.line-one {
				display: none;
			}
			&.line-two {
				display: block;
				top: clamp(80px, 10vw, 160px);
			}
			&.line-three {
				display: none;
			}
		}
	}
	section.banner + section.sub-service-info {
		.container {
			&--inner {
				.heading-wrapper {
					padding-right: 80px;
					padding-left: $spaceFromLine;
				}
			}
		}
		.line-track {
			&.line-one {
				display: block;
			}
			&.line-two {
				display: none;
			}
		}
	}
}
