section.key-section-links {
	.container {
		padding-top: clamp(60px, 10vw, 160px); // 160px @ 1600px
		padding-left: $spaceFromLine;
		position: relative;
		h2 {
			color: $primary;
			font-size: clamp(52px, 6.125vw, 98px); // 98px @ 1600px
			margin-bottom: 0;
		}
		.section-links-blocks {
			padding-top: clamp(40px, 3.9vw, 60px); // 40px @ 1024px
			display: grid;
			grid-template: 1fr / repeat(3, 1fr);
			align-items: start;
			gap: clamp(20px, 1.875vw, 30px); // 30px @ 1600px
			.section-links-block {
				display: flex;
				justify-content: flex-start;
				container-type: inline-size; // CQW @ 420px;
				a {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					.block-heading {
						color: $deep-thought-blue;
						font-size: clamp(34px, 12.38cqw, 52px); // CQW @ 420px
						font-weight: 400;
						line-height: 1.15em;
						margin-bottom: 0.25em;
						transition: color 0.2s ease-in-out;
					}
					.block-text {
						color: $deep-thought-blue;
						font-size: clamp(18px, 1.5vw, 24px); // CQW @ 420px
						font-weight: 400;
						line-height: 1.15em;
						margin-bottom: 0;
					}
					&:hover,
					&:active {
						.block-heading {
							color: $primary;
						}
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "large")) {
				grid-template: 1fr / 1fr;
				justify-content: start;
				gap: 40px;
				.section-links-block {
					a {
						.block-heading {
							color: $primary;
							font-size: clamp(36px, 9.23vw, 40px); // 36px @ 390px
						}
					}
				}
			}
		}
	}
	.line-strip {
		position: relative;
		margin-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
		height: clamp(80px, 10vw, 160px); // 160px @ 1600px
		.line-track {
			&.line-two {
				left: 50%;
			}
		}
	}
}
section.cta-banner + section.key-section-links {
	margin-top: 0;
	.container {
		padding-top: clamp(30px, 5vw, 80px); // 80px @ 1600px
	}
}
