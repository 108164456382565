section.service-solutions {
	background-color: $primary;
	padding: clamp(80px, 10vw, 160px) 0; // 160px @ 1600px
	.container {
		margin-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
		position: relative;
		// padding-left: $spaceFromLine;
		h2 {
			color: white;
			font-size: clamp(38px, 6.125vw, 98px); // 98px @ 1600px;
			margin: 0;
			position: relative;
		}
		.image-wrapper {
			position: relative;
			overflow: hidden;
			width: 100%;
			aspect-ratio: 1.7;
		}
		.solutions-widget {
			display: grid;
			grid-template: 1fr / 1fr auto;
			align-items: start;
			.image-column {
				// padding-right: clamp(15px, 1.875vw, 30px); // 30px @ 1600px
				padding-right: clamp(10px, 0.9375vw, 15px); // 15px @ 1600px
				align-self: stretch;
			}
			.controls-column {
				.control {
					position: relative;
					display: grid;
					grid-template: 1fr / clamp(30px, 3.75vw, 60px) clamp(20px, 2.5vw, 40px) 1fr; // VW @ 1600px;
					font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
					line-height: 1.05em;
					.number {
						justify-self: end;
						display: block;
						font-size: inherit;
						color: white;
						cursor: pointer;
						line-height: inherit;
						padding: 10px 0;
						opacity: 1;
						transition: opacity 0.3s ease-in-out;
						&:first-child {
							padding-top: 0;
						}
						&:last-child {
							padding-top: 0;
						}
					}
					.line-track {
						position: relative;
						align-self: stretch;
						justify-self: center;
						.line {
							height: 100%;
						}
					}
					&--content {
						padding: 10px 0;
						&:first-child {
							padding-top: 0;
						}
						&:last-child {
							padding-top: 0;
						}
						h3,
						a.h3 {
							display: block;
							color: $secondary;
							font-size: inherit;
							line-height: inherit;
							margin: 0;
							cursor: pointer;
							transition: color 0.3s ease-in-out;
							max-width: 12.5em;
							svg {
								opacity: 1;
								height: 0.5em;
								transition: opacity 0.3s ease-in-out;
							}
						}
						.textarea {
							container-type: inline-size; // stops this pushing content width out
							overflow: hidden;
							opacity: 1;
							transition: opacity 0.3s ease-in-out;
							padding: 10px 0 20px 0;
							p {
								font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
								line-height: 1.3;
								color: white;
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
					&:not(.active) {
						.number {
							opacity: 0.36;
						}
						.control--content {
							h3,
							a.h3 {
								color: white;
								svg {
									opacity: 0;
								}
							}
							.textarea {
								opacity: 0;
							}
						}
					}
					&:last-child {
						.control--content {
							.textarea {
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
		.mobile-solutions {
			.image-wrapper {
				margin: 50px 0;
			}
			.solutions-block {
				h3 {
					color: white;
					font-size: clamp(38px, 9.743vw, 45px); // 38px @ 390px
					margin-bottom: 0.4em;
				}
				.textarea {
					p {
						font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
						color: white;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				&:not(:first-child) {
					margin-top: 45px;
				}
			}
		}
	}
	@media screen and (min-width: map-get($min-breakpoints, "large")) {
		.container {
			h2 {
				width: 1px; // force it to wrap
				top: -0.5em;
			}
			.mobile-solutions {
				display: none;
			}
			.line-track {
				&.line-one {
					display: none;
				}
			}
		}
	}
	@media screen and (max-width: map-get($breakpoints, "large")) {
		padding: 0 0 clamp(120px, 15vw, 240px) 0; // 240px @ 1600px
		.container {
			padding: clamp(120px, 15vw, 240px) 0 0 0; // 240px @ 1600px
			padding-left: $spaceFromLine;
			margin-top: 0;
			h2 {
				margin-bottom: 0.1em;
			}
			.solutions-widget {
				display: none;
			}
		}
	}
}
