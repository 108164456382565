/* ---- 404 PAGE ---- */
section.banner-404 {
	.background-image {
		position: relative;
		width: 100%;
		height: 405px;
		img {
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			object-fit: cover;
			object-position: 100% 0%;
		}
	}
	// margin-bottom: -1px;
	// background-position: 0 0;
	// background-size: cover;
	// background-repeat: no-repeat;
	// display: flex;
	// align-items: flex-end;
	.container {
		padding: 200px 0 clamp(80px, 12.5vw, 150px) 0;
		min-height: clamp(500px, 56.25vw, 100vh);
		display: flex;
		align-items: flex-end;
		position: relative;
		z-index: 2;
		&--inner {
			position: relative;
			width: clamp(275px, 34.375vw, 550px); // 550px @ 1600px
			max-width: 100%;
			overflow: hidden;
			padding-left: $spaceFromLine;
		}
		h1 {
			font-size: clamp(120px, 15vw, 240px); // 240px @ 1600px
			color: $primary;
			margin: 0 0 0.2em 0;
			margin: 0;
			line-height: 1em;
		}
		h2 {
			font-weight: 500;
			font-size: clamp(30px, 3.75vw, 60px); // 60px @ 1600px;
			color: $primary;
		}
		.button-container {
			padding-top: 15px;
		}
		.line-track {
			&.line-one {
				top: clamp(15px, 1.875vw, 30px); // 30px @ 1600px;
			}
		}
	}
	@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
		.background-image {
			position: absolute;
			top: -1px;
			right: -1px;
			bottom: -1px;
			left: -1px;
			z-index: 1;
			height: unset;
			width: unset;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		.container {
			width: 100%;
			padding: 0;
			min-height: unset;
			&--inner {
				width: 100%;
				padding: $mobileGutter $mobileGutter 0 $mobileGutter;
				background-color: white;
				.content-wrapper {
					position: relative;
					padding-left: $spaceFromLine;
					padding-bottom: 36px;
					.line-track {
						&.line-one {
							top: 15px;
						}
					}
				}
				h1 {
					font-size: 100px;
				}
				h2 {
					font-size: 30px;
					max-width: 270px;
				}
				.banner-logo-container {
					width: 74.358vw; //290px @ 390px
					max-width: 500px;
				}
			}
		}
	}
}
