section.resources-grid {
	margin-top: 0;
	.container {
		position: relative;
		// padding-bottom: clamp(60px, 10vw, 160px); // 160px @ 1600px
		.resources-cards {
			padding-left: $spaceFromLine;
			display: grid;
			grid-template: 1fr / repeat(3, 1fr);
			gap: clamp(18px, 1.875vw, 30px); // 30px @ 1600px
			.resources-card {
				opacity: 0;
				transition: opacity 1s ease;
			}
			&.live {
				.resources-card {
					opacity: 1;
					// loop must be longer than expected number of each load
					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							transition-delay: #{$i * 150}ms;
						}
					}
				}
			}
			.resources-card {
				background-color: $primary;
				position: relative;
				overflow: hidden;
				display: flex;
				align-items: stretch;
				width: 100%;
				container-type: inline-size; // CQW @ 420px
				.content-wrapper {
					position: relative;
					z-index: 2;
					width: auto;
					padding: 4.761cqw; // 20px @ 420px
					padding-bottom: 3.571cqw; // 15px @ 420px
					display: grid;
					grid-template: 1fr auto / 1fr auto;
					gap: 9.523cqw 0; // 40px @ 420px
					transform: translateY(0);
					transition: transform 0.3s ease-in-out;
					h3 {
						grid-column: 1 / -1;
						grid-row: 1;
						color: white;
						font-size: 6.428cqw; // 27px @ 420px
						// white-space: nowrap;
						// overflow: hidden;
						// text-overflow: ellipsis;
						margin: 0;
						line-height: 1.05em;
						transition: color 0.3s ease-in-out;
					}
					.post-details {
						grid-column: 1;
						grid-row: 2;
						display: flex;
						align-items: center;
						color: white;
						font-size: 3.809cqw; // 16px @ 420px
						span {
							&:not(:first-child) {
								border-left: 1px solid white;
								padding-left: 8px;
								margin-left: 8px;
							}
						}
					}
					.download-icon {
						grid-column: 2;
						grid-row: 2;
						display: flex;
						justify-content: flex-end;
						svg {
							height: 7.619cqw; // 32px @ 420px
						}
					}
				}
				&:hover {
					.content-wrapper {
						h3 {
							color: $secondary;
						}
					}
				}
			}
			@media screen and (min-width: map-get($min-breakpoints, "medium")) and (max-width: map-get($breakpoints, "large")) {
				grid-template: 1fr / 1fr 1fr;
			}
			@media screen and (max-width: map-get($breakpoints, "medium")) {
				grid-template: 1fr / 1fr;
			}
		}
		.resources-cards + .resources-cards {
			padding-top: clamp(18px, 1.875vw, 30px); // 30px @ 1600px
		}
		// .button-container {
		// 	align-items: center;
		// 	justify-content: center;
		// 	padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px;
		// 	padding-bottom: 20px;
		// }
	}
}
