#wpadminbar {
	top: auto;
	bottom: 0;
}

@media screen and (max-width: map-get($breakpoints, tablet-portrait)) {
	#wpadminbar {
		display: none;
	}
}

.above {
	margin-top: 0 !important;
}

body,
html {
	height: 100%;
	min-height: 100%;
	margin: 0;
}

body {
	background-color: $body-background;
	// position: relative;
	width: 100%;
}

img {
	width: 100%;
	height: auto;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.section {
	margin-top: $section-space;
	position: relative;
	// padding-top: $section-space;
}
// .section.light-background + .section.light-background,
.banner.light-background + .section.light-background,
.section.dark-background + .section.dark-background,
main > .follow-close + .section.dark-background {
	margin-top: 0;
}

table tr th {
	text-align: left;
	padding: 10px;
	background-color: #f6f6f6;
}

table tr td {
	padding: 5px 10px;
	background-color: #f6f6f6;
}

$mobileGutter: 36px;
$spaceFromLine: clamp(16px, 2.5vw, 40px); // 40px @ 1600px
@each $class, $properties in $containerSizes {
	.c--#{$class} {
		@each $def, $val in $properties {
			#{$def}: #{$val};
		}
		margin-left: auto;
		margin-right: auto;
		@if $class != max and $class != xl {
			@media screen and (max-width: map-get($breakpoints, "xlarge")) {
				width: 88.5%;
			}
		}
		@if $class != max {
			@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
				width: calc(100% - (2 * $mobileGutter));
			}
		}
	}
}

// --- COLOURS
.color {
	&--primary {
		color: $primary;
	}
	&--secondary {
		color: $secondary;
	}
}

// --- BACKGROUNDS
.background {
	&--primary {
		background-color: $primary;
	}
	&--secondary {
		background-color: $secondary;
	}
}

// --- HELPERS
.title-container {
	text-align: center;
}

.cntr {
	text-align: center;
}

.image-container {
	position: relative;
	overflow: hidden;
}

.image-container-absolute {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.img-center {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 100%;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;
	pointer-events: none;
}

.text-center {
	text-align: center;
}

ul.no-list,
.no-list ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

.theme {
	&--light {
		&,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a,
		li {
			color: $paragraph-light-colour;
		}
	}
	&--dark {
		&,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		a,
		li {
			color: $paragraph-dark-colour;
		}
	}
}

.box-shadow {
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
}

.hidden {
	display: none;
}

// HIDES ELEMENTS RESPONSIVLEY
@each $size, $width in $breakpoints {
	@media screen and (max-width: $width) {
		.hide--#{$size} {
			display: none !important;
		}
	}
	@media screen and (min-width: $width) {
		.show--#{$size} {
			display: none !important;
		}
	}
}
