section.global-contact-banner {
	background-color: $deep-thought-blue;
	position: relative;
	margin: 0;
	.container {
		padding: 200px 0 80px 0;
		position: relative;
		.line-track {
			left: 50%;
			bottom: unset;
			height: 200px;
			.line {
				background-color: $secondary;
			}
		}
		.button-container {
			display: flex;
			align-items: center;
			justify-content: center;
			.global-contact-button {
				// visibility: hidden;
				position: relative;
				overflow: hidden;
				color: white;
				background-color: $deep-thought-blue;
				font-size: clamp(26px, 4.48vw, 56px); // 56px @ 1250px
				font-weight: 400;
				line-height: 1em;
				padding: 0.35em 1.1em 0.45em 1.1em;
				.title {
					position: relative;
					display: block;
					z-index: 2;
					opacity: 0;
					transition: opacity 0.2s 0s ease-in-out, background-position 0.3s ease-in-out;
					background-image: linear-gradient(to right, $secondary, $secondary 50%, #fff 50%);
					background-size: 200% 1em;
					background-position: 100%;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				&:hover {
					.title {
						background-position: 0;
					}
				}
				.border {
					position: absolute;
					background-color: $secondary;
					transition: all 0s;
					z-index: 2;
					display: block;
				}
				.line-top-left,
				.line-bottom-left,
				.line-top-right,
				.line-bottom-right {
					height: 2px;
					width: 0;
				}
				.line-side-right,
				.line-side-left {
					width: 2px;
					height: 0;
				}
				.line-top-left {
					top: 0;
					right: calc(50% - 1px);
				}
				.line-side-left {
					top: 0;
					left: 0;
				}
				.line-bottom-left {
					bottom: 0;
					left: 0;
				}
				.line-top-right {
					top: 0;
					left: calc(50% - 1px);
				}
				.line-side-right {
					top: 0;
					right: 0;
				}
				.line-bottom-right {
					bottom: 0;
					right: 0;
				}
				&.triggered {
					// visibility: visible;
					.title {
						opacity: 1;
						transition: opacity 0.6s 0.3s ease-in-out, background-position 0.3s ease-in-out;
					}
					.line-top-left {
						width: calc(50% + 1px);
						transition: all 0.3s ease-in;
					}
					.line-side-left {
						height: 100%;
						transition: all 0.2s 0.3s;
					}
					.line-bottom-left {
						width: calc(50% + 1px);
						transition: all 0.3s 0.5s ease-out;
					}
					.line-top-right {
						width: calc(50% + 1px);
						transition: all 0.3s ease-in;
					}
					.line-side-right {
						height: 100%;
						transition: all 0.2s 0.3s;
					}
					.line-bottom-right {
						width: calc(50% + 1px);
						transition: all 0.3s 0.5s ease-out;
					}
				}
			}
		}
	}
}

section.light-background:not(.follow-close) + section.global-contact-banner {
	margin-top: $section-space;
}
