section.latest-insights {
	margin: 0;
	// this font-size is necessary to align with Brand Messaging Carousel line
	font-size: clamp(42px, 10.625vw, 170px); // 170px @ 1600px
	padding: 0;
	.container {
		position: relative;
		padding: 0;
		.latest-insight-columns {
			display: grid;
			grid-template: 1fr / auto 1fr;
			gap: clamp(20px, 3.4375vw, 55px); // 55px @ 1600px
			.heading-wrapper {
				// padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
				position: relative;
				min-width: 1.325em;
				align-self: start;
				h2 {
					color: $primary;
					font-size: inherit;
					margin: 0;
					@media screen and (min-width: map-get($min-breakpoints,"large")) {
						white-space: nowrap;
						writing-mode: vertical-rl;
						text-orientation: mixed;
						line-height: 1.3em;
					}
				}
			}
			.latest-insights-cards {
				// padding-top: clamp(80px, 10vw, 160px);
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				.cards-wrapper {
					// align-self: end;
					display: grid;
					grid-template: 1fr / repeat(2, 1fr);
					gap: clamp(20px, 3.4375vw, 55px); // 55px @ 1600px
					align-items: stretch;
					@media screen and (min-width: map-get($min-breakpoints,"large")) {
						padding-bottom: 5.625vw;
					}
					.latest-insight-card {
						position: relative;
						display: grid;
						grid-template: auto 1fr auto / 1fr;
						container-type: inline-size;
						.image-wrapper {
							position: relative;
							width: 100%;
							height: auto;
							min-height: 185px;
							aspect-ratio: 1.789;
							overflow: hidden;
							z-index: 1;
							.img-center {
								object-fit: cover;
							}
						}
						h3 {
							font-size: 7.058cqw; //36px @ 510px
							font-weight: 500;
							color: $primary;
							margin: 0.8em 0 0.4em 0;
							transition: color 0.3s ease-in-out;
						}
						.button-container {
							align-items: flex-start;
							justify-content: flex-start;
						}
						&:hover {
							h3 {
								color: $deep-thought-blue;
							}
							.button-container {
								.slide-button {
									> span {
										background-position: 0;
									}
								}
							}
						}
						@media screen and (min-width: map-get($min-breakpoints,"large")) {
							&:nth-child(2n - 1) {
								top: 5.625vw; // 90px @ 1600px;
							}
						}
						@media screen and (min-width: map-get($min-breakpoints,"tablet-portrait")) and (max-width: map-get($breakpoints,"large")) {
							&:nth-child(2n) {
								top: -5.625vw; // 90px @ 1600px;
							}
						}
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints,"large")) {
				display: block;
				.heading-wrapper {
					h2 {
						font-size: clamp(42px, 8.125vw, 130px); // 130px @ 1600px
						margin-bottom: 0.8em;
					}
				}
				.latest-insights-cards {
					padding: 0 0 0 $spaceFromLine;
				}
			}
			@media screen and (max-width: map-get($breakpoints,"tablet-portrait")) {
				.heading-wrapper {
					h2 {
						margin-bottom: 0.5em;
					}
				}
				.latest-insights-cards {
					.cards-wrapper {
						grid-template: 1fr / 1fr;
						gap: 8.974vw; // 35px @ 390px
						.latest-insight-card {
							top: 0 !important;
							.image-wrapper {
								max-height: 285px;
							}
							h3 {
								font-size: clamp(25px, 6.41vw, 40px); // 25px @ 390px
							}
							&:nth-child(n + 3) {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	.line-track {
		&.line-one {
			left: 1.325em;
		}
		&.line-two {
			left: 50%;
		}
		&.line-three {
			display: none;
		}
		@media screen and (max-width: map-get($breakpoints,"large")) {
			&.line-one {
				display: none;
			}
			&.line-three {
				display: block;
			}
		}
		@media screen and (max-width: map-get($breakpoints,"large")) {
			&.line-two {
				top: clamp(30px, 7.692vw, 50px); // 30px @ 390px
			}
		}
	}
	&:not(.single-post, .front-page, .resources-page) {
		.container {
			.section-footer {
				padding: 0 0 clamp(80px, 10vw, 160px) 0; // 160px @ 1600px
				.button-container {
					align-items: flex-end;
					justify-content: flex-end;
				}
				@media screen and (max-width: map-get($breakpoints,"large")) {
					margin-top: clamp(50px, 12.82vw, 100px); // 50px @ 390px
					.button-container {
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
	&.single-post,
	&.front-page,
	&.resources-page {
		.container {
			.section-footer {
				margin-top: clamp(125px, 10vw, 250px); // 250px @ 1600px
				padding-bottom: clamp(100px, 10vw, 160px); // 160px @ 1600px
				position: relative;
				padding-left: 51.875%;
				@media screen and (max-width: map-get($breakpoints,"large")) {
					margin-top: clamp(50px, 12.82vw, 100px); // 50px @ 390px
					padding-left: 0;
					.button-container {
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
}
section.dark-background + section.latest-insights {
	.container {
		.latest-insight-columns {
			.heading-wrapper {
				padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
			}
			.latest-insights-cards {
				padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
			}
		}
	}
}
