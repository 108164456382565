.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 13px;
    line-height: 19px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark:after {
            display: block;
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: white;
        border: 1px solid $primary;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 7px;
            top: -5px;
            width: 5px;
            height: 13px;
            border: solid #1b365d;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
            display: none;
        }
    }

    &:hover input ~ .checkmark {
        background-color: #ccc;
    }
}
