section.services-overview {
	background-color: $primary;
	.container {
		padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
		&--inner {
			width: 100%;
			.services-upper {
				width: 55%;
				padding-bottom: clamp(60px, 5vw, 80px); // 80px @ 1600px
				h2 {
					font-size: clamp(42px, 8.125vw, 130px); // 130px @ 1600px
					color: white;
					margin-bottom: 0.05em;
				}
				.textarea {
					color: white;
					p {
						font-size: clamp(18px, 2vw, 32px); // 32px @ 1600px
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			.service-panels {
				position: relative;
				display: grid;
				gap: clamp(30px, 3.75vw, 60px); // 60px @ 1600px
				grid-template: 1fr /1fr 50%;
				padding-bottom: clamp(120px, 15vw, 240px); // 240px @ 1600px
				.service-panel {
					// display: grid;
					// grid-template: auto 1fr auto / 1fr;
					h3 {
						font-size: clamp(38px, 5.625vw, 90px); // 90px @ 1600px
						color: white;
						margin-bottom: 0.2em;
					}
					.textarea {
						color: white;
						p {
							font-size: clamp(18px, 1.5vw, 24px); // 24px@ 1600px
							&:last-child {
								margin-bottom: 0;
							}
						}
					}
					.button-container {
						padding-top: clamp(15px, 1.875vw, 30px); // 30px @ 1600px
						// .button {
						// 	font-size: clamp(16px, 2.562vw, 41px); // 41px @ 1600px
						// }
					}
					@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
						&:last-child {
							padding-left: $spaceFromLine;
						}
					}
				}
				.line-track {
					&.line-one {
						left: 50%;
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
				.services-upper {
					width: unset;
				}
				.service-panels {
					display: block;
					padding-left: $spaceFromLine;
					.service-panel + .service-panel {
						margin-top: 40px;
					}
					.line-track {
						&.line-one {
							left: 0;
						}
					}
				}
			}
		}
	}
}
