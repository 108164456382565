section.introduction {
	margin-top: 0;
	background-color: $primary;
	// padding: 0 0 160px 0;
	position: relative;
	.container {
		position: relative;
		padding: clamp(60px, 10vw, 160px) 0; // 160px @ 1600px
		padding-left: 30px;
		&--inner {
			// width: 50vw;
			// min-width: 290px;
			h2 {
				font-size: clamp(38px, 6.125vw, 98px); // 98px @ 1600px;
				line-height: 0.8em;
				color: $deep-thought-blue;
				margin: 0;
				font-weight: 400;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				span {
					height: 1em;
					display: inline-block;
					white-space: nowrap;
					background-size: 200% 100%;
					background-position: 100% 200px;
					-webkit-background-clip: text;
					background-clip: text;
					-webkit-text-fill-color: transparent;
					&.blue-text {
						// color: $secondary;
						background-image: linear-gradient(to right, $secondary, $secondary 50%, #7893f3 50%);
					}
					&.white-text {
						// color: white;
						background-image: linear-gradient(to right, white, white 50%, #7893f3 50%);
					}
				}
			}
		}
		.line-track {
			&.line-one {
				bottom: clamp(60px, 10vw, 160px); // 160px @ 1600px
			}
		}
	}
}
