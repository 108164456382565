section.logo-wall {
	background-color: $primary;
	.container {
		padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
		padding-bottom: clamp(60px, 7.5vw, 120px); // 80px @ 1600px
		h2 {
			color: white;
			font-size: clamp(42px, 6.125vw, 98px); // 98px @ 1600px
			text-align: center;
			margin: 0 auto;
			width: 80%;
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			h2 {
				width: auto;
			}
		}
	}
	.logo-grid {
		position: relative;
		padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
		container-type: inline-size;
		.logo-grid-items {
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 5cqw 7.5cqw; // CQW @ 1360px;
			.logo-wrapper {
				background-color: $primary;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 19.117cqw; // 260px @ 1360px
				height: auto;
				aspect-ratio: 1.8;
				position: relative;
				overflow: hidden;
				img {
					max-width: 100%;
					max-height: 100%;
					object-fit: scale-down;
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.logo-grid-items {
				gap: 30px 15cqw; // CQW @ 511px;
				.logo-wrapper {
					width: 39.138cqw; // 200px @ 511px
				}
			}
		}
	}
	.line-track {
		left: 50%;
	}
}
