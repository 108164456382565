section.view-more-services {
	margin-top: 0;
	padding: clamp(60px, 7.5vw, 120px) 0 0 0; // 120px @ 1600px
	position: relative;
	.container {
		position: relative;
		.link-wrapper {
			width: 50%;
			margin: 0 0 0 auto;
			h2 {
				position: relative;
				left: -0.1em;
				font-size: clamp(25px, 3.75vw, 60px); // 60px @ 1600;
				color: $primary;
				text-align: left;
				line-height: 1.15em;
				margin-bottom: 0.2em;
				overflow: visible;
			}
		}
		.button {
			font-size: inherit;
			font-weight: inherit;
			line-height: 1.2em;
		}
		.line-wrapper {
			height: clamp(100px, 12.5vw, 200px); // 200px @ 1600px
			position: relative;
			.line-track {
				&.line-one {
					left: 50%;
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.link-wrapper {
				width: auto;
				h2 {
					left: unset;
					margin-bottom: 20px;
					font-size: clamp(25px, 6.41vw, 45px); // 25px @ 390;
					text-align: center;
				}
			}
		}
	}
}
section.light-background + section.view-more-services {
	padding-top: 0;
}
