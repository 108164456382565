section.our-values {
	background-color: $deep-thought-blue;
	.container {
		padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
		h2 {
			font-size: clamp(52px, 6.125vw, 98px); // 98px @ 1600px
			color: white;
			margin: 0 0 1em 0;
		}
	}
	.grid-wrapper {
		.values-grid {
			display: grid;
			grid-template: 1fr / repeat(4, 1fr);
			gap: clamp(15px, 1.875vw, 30px); // 30px @ 1600px
			.values-card {
				h3 {
					color: $secondary;
					font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
					margin-bottom: 15px;
				}
				.image-wrapper {
					width: 100%;
					height: auto;
					aspect-ratio: 380 / 440;
					position: relative;
					overflow: hidden;
					img {
						object-fit: cover;
					}
				}
				&.no-image {
					.image-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: $primary;
						opacity: 0.5;
						&::after {
							color: $deep-thought-blue;
							font-size: 16px;
							content: "NO IMAGE";
						}
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
				grid-template: 1fr / 1fr 1fr;
				gap: 45px clamp(15px, 3.75vw, 30px); // 30px @ 800px
			}
			@media screen and (max-width: map-get($breakpoints, "small")) {
				grid-template: 1fr / 1fr;
			}
		}
	}
	.line-wrapper {
		height: clamp(100px, 12.5vw, 200px); // 200px @ 1600px
		position: relative;
	}
}
