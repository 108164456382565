section.position-post {
	margin: 0;
	.container {
		padding-top: clamp(150px, 25vw, 300px); // 150px @ 600px
		.position-content {
			position: relative;
			padding-left: $spaceFromLine;
			h1 {
				font-size: clamp(52px, 6.125vw, 98px);
				color: $primary;
				margin: 0;
			}
			.content-block {
				padding-top: clamp(40px, 3.75vw, 60px); // 80px @ 1600px
				h2 {
					font-size: clamp(25px, 2.812vw, 45px);
					color: $primary;
					margin-bottom: 0.4em;
				}
				.wysiwyg {
					ul,
					ol {
						padding-inline-start: 1.4em;
					}
					p,
					li {
						// color: $deep-thought-blue;
						font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
					}
					> *:last-child {
						margin-bottom: 0;
					}
					li + li {
						margin-top: 0.75em;
					}
				}
			}
		}
		.upper-button {
			padding: 0 0 30px 0;
		}
		.lower-button {
			align-items: center;
			justify-content: center;
			padding: 80px 0 20px 0;
		}
		.form-wrapper {
			max-width: 750px;
			padding-top: 20px;
			// padding-top: 40px;
			.button-container {
				// align-items: center;
				// justify-content: center;
				padding-top: 20px;
				.button {
					font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
					// font-weight: 400;
				}
			}
		}
	}
	.line-wrapper {
		position: relative;
		padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
		.line-track {
			&.line-two {
				left: 50%;
			}
		}
	}
}
