.lightbox {
    &--container {
        display: flex;
        align-items: center;
        
        .splide__list {
            align-items: center;
        }
        .splide__slide {
            text-align: center;
        }
        .splide__pagination {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
        }
        img {
            max-height: 90vh;
            width: auto;
        }
    }
}