section.latest-case-studies {
	background-color: $primary;
	// overflow: hidden;
	padding: clamp(40px, 5vw, 80px) 0 clamp(125px, 15.625vw, 250px) 0; // 250px @ 1600px
	.container {
		// border: 1px dashed red;
		// padding: clamp(40px, 5vw, 80px) 0; // 80px @ 1600px
		.heading-wrapper {
			width: 10px;
			overflow: visible;
			h2 {
				font-size: clamp(42px, 8.125vw, 130px); // 130px @ 1600px
				color: white;
				line-height: 0.861em;
				margin-bottom: 0.2em;
			}
		}
		.case-study-cards {
			@media screen and (max-width: map-get($breakpoints, "large")) {
				.case-study-card:nth-child(n + 3) {
					display: none;
				}
			}
		}
		.button-container {
			pointer-events: none;
			padding-top: 12vw;
			align-items: center;
			justify-content: center;
			.button {
				pointer-events: all;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "medium")) {
			.heading-wrapper {
				width: unset;
				h2 {
					margin-bottom: 0.8em;
				}
			}
			.button-container {
				display: none !important;
			}
		}
	}
	.line-track.line-one {
		left: 50%;
		top: unset;
		height: clamp(40px, 5vw, 80px); // 80px @ 160px;
	}
	&.split-background {
		background-image: linear-gradient(to bottom, $primary, $primary 50%, white 50%);
		.button-container {
			padding-top: clamp(10px, 12vw, 200px);
			// 	transform: translateY(-1.1em);
			// 	align-items: flex-end;
			// 	justify-content: flex-end;
		}
		.line-track.line-one {
			left: 50%;
			// top: 30%;
			top: unset;
			// height: unset;
			height: clamp(10px, 10vw, 200px);
			.line {
				background-color: $primary !important;
			}
		}
		// @media screen and (max-width: map-get($breakpoints, "large")) {
		// .button-container {
		// 	padding-top: 0;
		// 	transform: translateY(calc(5.625vw - 1em));
		// }
		// }
		@media screen and (max-width: map-get($breakpoints, "medium")) {
			background-image: linear-gradient(to bottom, $primary, $primary 30%, white 30%);
		}
	}
	&.case-study {
		padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
	}
}
