/* --- ANIMATION CSS --- */
#smooth-scroll{
    will-change: transform;
    overflow: hidden;
}
#smooth-scroll .rellax{
    transition: transform 0.2s ease-out;
}
#smooth-scroll .anim-scroll-fade-out{
    transition: opacity 0.1s ease-out;
}
.anim-scroll-trigger,
.anim-instant{
    opacity: 0;
    will-change: transform, opacity;
}

.anim-scroll{
    will-change: opacity;
}

.anim-scroll-trigger[data-anim="slide-down"]{
    opacity: 1;
}
.anim-scroll-trigger[data-anim="text-reveal-up"],
.anim-scroll-trigger[data-anim="overlay-reveal-right"],
.anim-instant[data-anim="text-reveal-up"],
.anim-instant[data-anim="text-reveal-right"]{
    overflow: hidden;
}
.anim-text-reveal,
.anim-scroll-trigger[data-anim="text-reveal-up"]{
    display: block;
}
.anim-scroll-trigger[data-anim="overlay-reveal-down"]{
    opacity: 1;
    will-change: unset;
}
.anim-scroll-trigger .anim-image-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    will-change: transform, opacity;
}