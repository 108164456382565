.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display: none;
    &.show {
        display: flex;
    }
    &.animate {
        opacity: 1;
    }
    
    img {
        width: 150px;
    }

    &--small {
        img {
            width: 70px;
        }
    }
}