/* ---- COLUMNS ---- */
.columns {
    display: flex;
    justify-content: space-between;

    &.col--1 > .col {
        flex: 100%;
    }
    &.col--2 > .col {
        flex: 0 1 50%;
    }
    &.col--3 > .col {
        flex: 0 1 33.333%;
    }
    &.col--4 > .col {
        flex: 0 1 25%;
    }
    &.col--5 > .col {
        flex: 0 1 20%;
    }
    &.col--6 > .col {
        flex: 0 1 16.6666%;
    }
}

@media screen and (max-width: map-get($breakpoints, 'tablet-portrait')) {
    .columns {
        flex-direction: column;
    }
}