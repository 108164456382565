section.generic-content {
	padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
	.container {
		position: relative;
		padding-left: $spaceFromLine;
		h2 {
			font-size: clamp(25px, 2.812vw, 45px);
			color: $primary;
			margin-bottom: 0.4em;
		}
		.wysiwyg {
			overflow: hidden;
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $primary;
				b {
					font-weight: inherit;
				}
			}
			h2 {
				font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
				color: $primary;
				margin-bottom: 0.4em;
			}
			h3 {
				font-size: clamp(20px, 2vw, 32px); // 32px @ 1600px
				color: $primary;
				margin-bottom: 0.4em;
			}
			a {
				color: $primary;
				font-weight: inherit;
				&:hover {
					text-decoration: underline;
				}
			}
			ul,
			ol {
				padding-inline-start: 1.4em;
				margin-block-end: 1em;
			}
			p,
			li,
			td,
			th {
				color: $deep-thought-blue;
				font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
			}
			p {
				margin-bottom: 1em;
			}
			> *:last-child {
				margin-bottom: 0;
			}
			li + li {
				margin-top: 0.2em;
			}
			table,
			ul,
			ol,
			p {
				+ h2,
				+ h3,
				+ h4,
				+ h5,
				+ h6 {
					margin-top: 1.5em;
				}
			}
			// table {
			// margin: 36px 0;
			// }
			.table-wrapper {
				max-width: 100%;
				overflow: hidden;
				overflow-x: auto;
				&:not(:last-child) {
					margin: 36px 0;
				}
				table {
					min-width: 800px;
				}
			}
			img {
				display: block;
				max-width: 100%;
				width: auto;
				height: auto;
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
