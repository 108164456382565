.banner {
	position: relative;
	overflow: hidden;
	h1,
	h2,
	h3,
	p {
		color: white;
	}
	&--home {
		background-color: #d8d8d8;
		.background-image {
			position: relative;
			width: 100%;
			height: 405px;
			img {
				width: calc(100% + 2px);
				height: calc(100% + 2px);
				object-fit: cover;
				object-position: 100% 0%;
				filter: blur(30px);
			}
		}
		.container {
			position: relative;
			// height: 62.5vw;
			// max-height: 500px;
			min-height: clamp(500px, 56.25vw, 100vh);
			padding-top: 150px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			z-index: 2;
			&--inner {
				position: relative;
				container-type: inline-size;
				.banner-logo-container {
					width: 35vw; //560px @ 1600px
					max-width: 560px;
					min-width: 290px;
					opacity: 0;
					svg {
						fill: $primary;
					}
				}
				.heading-wrapper {
					position: relative;
					// padding: 30px 0 0 $spaceFromLine;
					padding: 30px 0 0 0;
					padding-bottom: 80px;
				}
				.line-track {
					top: 30px;
					.line {
						height: 0%;
					}
				}
				h1 {
					font-family: StraplineFont, $default-font;
					width: min-content;
					color: $primary;
					font-size: clamp(38px, 4.25vw, 68px); // 68px @ 1600px
					font-weight: 400;
					margin: 0;
					line-height: 0.9em;
					// opacity: 0;
					span {
						opacity: 0;
						display: block;
						overflow: hidden;
						padding-left: $spaceFromLine;
					}
				}
			}
		}
		@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
			.background-image {
				position: absolute;
				top: -1px;
				right: -1px;
				bottom: -1px;
				left: -1px;
				z-index: 1;
				height: unset;
				width: unset;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.container {
				width: 100%;
				padding: 0;
				min-height: unset;
				&--inner {
					padding: $mobileGutter $mobileGutter 0 $mobileGutter;
					background-color: white;
					h1 {
						font-size: clamp(38px, 9.743vw, 68px); // 38px @ 390px
					}
					.banner-logo-container {
						width: 74.358vw; //290px @ 390px
						max-width: 500px;
					}
				}
			}
		}
	}
	&--subpage {
		.background-image {
			overflow: hidden;
			position: relative;
			z-index: 1;
			height: clamp(405px, 67.5vw, 600px); // 405px @ 600px
		}
		&.alt-burger {
			.background-image {
				display: none;
			}
		}
		// &:not(.alt-burger) {
		// 	.container {
		// min-height: clamp(500px, 60vw, 650px);
		// min-height: clamp(768px, 100vh, 900px);
		// 	}
		// }
		.container {
			// padding-top: 300px;
			padding-top: clamp(150px, 25vw, 300px); // 150px @ 600px
			display: flex;
			align-items: stretch;
			&--inner {
				width: 50%;
				display: grid;
				grid-template: auto 1fr / 1fr;
				.heading-wrapper {
					align-self: end;
					position: relative;
					h1 {
						color: $primary;
						pointer-events: none;
						// font-size: clamp(42px, 5.104vw, 82px); // 98px @ 1920px
						font-size: clamp(42px, 5.625vw, 90px); // 98px @ 1600px
						font-weight: $header-font-weight;
						margin: 0 0 0.25em 0;
						padding-right: 30px;
					}
				}
				.copy-wrapper {
					position: relative;
					.wysiwyg {
						// padding: 0 30px clamp(40px, 5vw, 80px) $spaceFromLine; // 80px @ 1600px;
						padding: 0 30px clamp(60px, 7.5vw, 120px) $spaceFromLine; // 120px @ 1600px;
						p {
							color: $deep-thought-blue;
							font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
							// margin: 0;
							// padding-right: 1em;
							&:first-child {
								font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
							}
							// &:last-child {
							// 	margin-bottom: 2em;
							// }
							b,
							strong {
								font-weight: inherit;
								color: $primary;
							}
						}
					}
				}
			}
		}
		@media screen and (min-width: map-get($min-breakpoints,"large")) {
			.background-image {
				position: absolute;
				height: unset;
				top: 0;
				right: 0;
				bottom: -1px;
				left: 50%;
				z-index: 1;
			}
			&:not(.alt-burger) {
				.container {
					// min-height: clamp(500px, 60vw, 650px);
					min-height: clamp(768px, 100vh, 900px);
				}
			}
			.container {
				&--inner {
					.heading-wrapper {
						.button {
							position: absolute;
							top: -2em;
							left: 0;
						}
					}
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints,"large")) {
			&:not(.alt-burger) {
				.container {
					padding-top: $mobileGutter;
				}
			}
			.container {
				min-height: 0;
				&--inner {
					width: auto;
					.heading-wrapper {
						h1 {
							margin: 0.15em 0 0.35em 0;
							padding-right: 0;
						}
						.button + h1 {
							margin-top: 1.6em;
						}
					}
					.copy-wrapper {
						.wysiwyg {
							max-width: 650px;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
	&--contact,
	&--resources,
	&--work,
	&--insights {
		.container {
			padding-top: clamp(150px, 18.75vw, 300px); // 300px @ 1600px;
			padding-top: clamp(180px, 22.5vw, 250px); // 180px @ 800px;
			&--inner {
				width: 50%;
				.heading-wrapper {
					align-self: end;
					position: relative;
					h1 {
						color: $primary;
						pointer-events: none;
						// font-size: clamp(42px, 5.104vw, 82px); // 98px @ 1920px
						font-size: clamp(42px, 5.625vw, 90px); // 98px @ 1600px
						font-weight: $header-font-weight;
						margin: 0 0 0.25em 0;
						padding-right: 30px;
					}
				}
				.copy-wrapper {
					position: relative;
					.wysiwyg {
						padding: 0 30px clamp(40px, 5vw, 80px) $spaceFromLine; // 80px @ 1600px;
						p {
							color: $deep-thought-blue;
							font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
							&:first-child {
								font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
							}
							b,
							strong {
								font-weight: inherit;
								color: $primary;
							}
						}
					}
					.line-track {
						bottom: -1px;
					}
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints,"large")) {
			.container {
				&--inner {
					width: auto;
					.heading-wrapper {
						h1 {
							margin: 0.15em 0 0.35em 0;
							padding-right: 0;
						}
					}
					.copy-wrapper {
						.wysiwyg {
							max-width: 650px;
							padding-right: 0;
						}
					}
				}
			}
		}
	}
	&--case-study {
		background-color: $deep-thought-blue;
		.container {
			&--inner {
				min-height: clamp(500px, 50vw, 800px); // 800px @ 1600px
				padding: clamp(180px, 15vw, 240px) 0; // 240px @ 1600px
				padding-left: $spaceFromLine;
				margin-left: 30%;
				position: relative;
				.button-container {
					flex-direction: row;
					align-items: center;
					padding-bottom: clamp(30px, 4.375vw, 70px); // 70px @ 1600px;
					> span {
						padding-left: 0.6em;
						margin-left: 0.6em;
						border-left: 1px solid white;
					}
					.read-length {
						color: white;
						white-space: nowrap;
						display: block;
						font-size: clamp(16px, 4.102vw, 20px);
						font-weight: 400;
					}
				}
				.heading-wrapper {
					position: relative;
					h1 {
						color: white;
						font-size: clamp(42px, 6.125vw, 98px); // 98px @ 1920px
						margin: 0 0 0.25em 0;
					}
					h2 {
						color: $secondary;
						font-size: clamp(38px, 5vw, 80px); // 80px @ 1920px
						margin: 0;
					}
				}
			}
			.line-track {
				&.line-two {
					display: none;
				}
			}
			@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
				&--inner {
					min-height: unset;
					margin-left: 0;
					padding: 160px 0 0 0;
					.heading-wrapper {
						min-height: clamp(240px, 42.5vw, 340px); // 340px @ 800px
						padding: 0 0 100px $spaceFromLine;
					}
				}
				.line-track {
					&.line-one {
						display: none;
					}
					&.line-two {
						display: block;
					}
				}
			}
		}
	}
	&--generic {
		.container {
			padding-top: clamp(150px, 18.75vw, 300px); // 300px @ 1600px;
			&--inner {
				width: 50%;
				.heading-wrapper {
					align-self: end;
					position: relative;
					h1 {
						color: $primary;
						pointer-events: none;
						// font-size: clamp(42px, 5.104vw, 82px); // 98px @ 1920px
						font-size: clamp(42px, 5.625vw, 90px); // 98px @ 1600px
						font-weight: $header-font-weight;
						margin: 0 0 0.6em 0;
						padding-right: 30px;
					}
				}
			}
		}
	}
	&--none {
		padding-top: 200px;
		margin-bottom: 40px;
		h1 {
			color: $primary;
			max-width: 900px;
			text-align: center;
			margin-right: auto;
			margin-left: auto;
		}
	}
}
