section.image-text-columns {
	background-color: $primary;
	padding-bottom: clamp(120px, 15vw, 240px); // 240px @ 1600px
	.container {
		position: relative;
		.image-text-rows {
			position: relative;
			.image-text-row {
				position: relative;
				.image-wrapper {
					position: relative;
					&--inner {
						width: 100%;
						height: auto;
						aspect-ratio: 1.65;
						overflow: hidden;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				.content-wrapper {
					position: relative;
					container-type: inline-size;
					&--inner {
						h3 {
							color: white;
							font-size: clamp(25px, 9.782cqw, 45px); // 45px @ 460px
						}
						.textarea {
							p {
								color: white;
								font-size: clamp(16px, 3.913cqw, 18px); // 18px @ 460px
								&:last-child {
									margin: 0;
								}
							}
						}
						.button-container {
							padding-top: 20px;
						}
					}
				}
				@media screen and (min-width: map-get($min-breakpoints, "large")) {
					display: flex;
					flex-direction: row;
					overflow: hidden;
					.image-wrapper {
						padding-top: clamp(80px, 10vw, 160px); // 160px @ 1600px
						width: 60%;
						align-self: flex-end;
					}
					.content-wrapper {
						padding: clamp(80px, 10vw, 160px) $spaceFromLine 0 $spaceFromLine; // 160px @ 1600px
						display: flex;
						width: 40%;
						align-self: flex-start;
						align-items: flex-start;
						justify-content: flex-end;
					}
					&:nth-child(odd) {
						flex-direction: row-reverse;
						.image-wrapper {
							padding-left: $spaceFromLine;
						}
					}
					&:nth-child(even) {
						.image-wrapper {
							padding-right: $spaceFromLine;
						}
					}
					@media screen and (max-width: map-get($breakpoints, "xlarge")) {
						.image-wrapper {
							width: 55%;
						}
						.content-wrapper {
							width: 45%;
						}
					}
				}
				@media screen and (max-width: map-get($breakpoints, "large")) {
					padding-left: $spaceFromLine;
				}
			}
		}
	}
	@media screen and (min-width: map-get($min-breakpoints, "large")) {
		.line-track {
			&.line-mobile {
				display: none;
			}
		}
	}
	@media screen and (max-width: map-get($breakpoints, "large")) {
		.container {
			padding-top: clamp(120px, 15vw, 240px); // 240px @ 1600px
			.image-text-rows {
				.image-text-row {
					.content-wrapper {
						padding-top: clamp(30px, 5vw, 80px); // 80px @ 1600px
						padding-bottom: clamp(60px, 7.5vw, 120px); // 120px @ 1600px
					}
					&:last-child {
						.content-wrapper {
							padding-bottom: 0;
						}
					}
				}
			}
			.line-track {
				&.line-desktop {
					display: none;
				}
				&.line-mobile {
					display: block;
				}
			}
		}
	}
}
