.dropdown {
	transition: all 0.2s ease-in-out;
	background: black;
	cursor: pointer;
	position: relative;
	margin-bottom: 0px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.13);
	min-width: 215px;
	z-index: 5;
	background-color: transparent;

	&--sub {
		min-width: auto;

		.dropdown__menu--wrapper {
			min-width: 278px;
		}
		.dropdown__menu {
			li {
				padding-top: 5px;
				padding-bottom: 5px;
			}
			li:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	button {
		width: 100%;
		font-size: 15px;
		font-family: $default-font;
		letter-spacing: 0.2px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 14px 20px 13px 18px;
		-webkit-appearance: none;
		outline: none;
		border: none;
		background-color: white;
		cursor: pointer;
		border-radius: 4px;
	}

	&__title {
		width: 100%;
	}

	&__icon {
		display: flex;
		justify-content: space-between;
		padding: 0px;
		transition: all 0.2s ease-in-out;
		transform: rotateX(180deg) rotateY(180deg);
	}

	&__menu {
		margin: 0px 0;
		padding: 20px 22px;
		list-style: none;

		&--wrapper {
			// height: 0;
			max-height: 0;
			transition: all 0.3s ease-in-out;
			overflow: hidden;
			position: absolute;
			width: 100%;
			top: 52px;
			background-color: white;
			z-index: 5;
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.13);
			border-radius: 4px;
			//min-width: 278px;
		}
		li {
			min-width: 100% !important;
			font-size: 12px;
			font-weight: 500;
			padding-top: 11px;
			padding-bottom: 7px;
			text-transform: uppercase;
			color: #888;
			transition: all 0.25s ease-in-out;

			& > span {
				position: relative;
				&:after {
					content: "";
					position: absolute;
					bottom: -6px;
					left: 0;
					width: 0%;
					height: 3px;
					background-color: $secondary;
					border-radius: 3px;
					transition: width 0.25s ease-in-out;
				}
			}

			&:not(:first-child) {
				margin-top: 6px;
			}

			&:hover,
			&.active {
				background: transparent;
				color: $primary;
			}
			&.active {
				span:after {
					width: 100%;
				}
			}
		}
	}
	.fa {
		transition: all 0.5s ease-in-out;
		transform: rotate(180deg);
		position: absolute;
		right: -5px;
		top: -28px;
		z-index: 2;
		width: 56px;
		height: 56px;
	}
	.fa:before {
		content: "";
		background-image: url("../images/2022NewSales/icons/up.png");
		width: 56px;
		height: 56px;
		top: 0;
		right: 0;
		background-size: contain;
		position: absolute;
		background-repeat: no-repeat;
	}
	&.closed .fa {
		transform: rotate(0);
	}
	&.closed .dropdown__menu {
		// height: 0;
	}
}

#sub-category.dropdown {
	.dropdown__menu {
		display: none;
		&.active {
			display: block;
		}
	}
}
