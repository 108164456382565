section.contact-details {
	margin-top: 0;
	.container {
		position: relative;
		padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
		.contact-grid {
			position: relative;
			display: grid;
			grid-template: 1fr / 1fr 1fr;
			gap: clamp(20px, 2.5vw, 40px) 0; // 40px @ 1600px
			.contact-grid-item {
				h3 {
					color: $deep-thought-blue;
					font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
					margin-bottom: 0.2em;
				}
				span,
				a {
					color: $primary;
					// font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
					font-size: clamp(20px, 1.875vw, 30px); // 30px @ 1600px
					transition: color 0.3s ease-in-out;
				}
				a:hover {
					color: $deep-thought-blue;
				}
				padding-left: $spaceFromLine;
				&:nth-of-type(even) {
					padding-right: clamp(40px, 5vw, 80px); // 80px @ 1600px
				}
			}
		}
		.line-track {
			&.line-one {
				left: 50%;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.contact-grid {
				grid-template: 1fr / 1fr;
				max-width: 450px;
			}
			.line-track {
				&.line-one {
					display: none;
				}
			}
		}
	}
}
section.contact-map {
	margin-top: 0;
	.container {
		.map-wrapper {
			width: 100%;
			height: 750px;
			.wpgmza_map {
				width: 100%;
				height: 100%;
			}
		}
	}
}
