.line-track {
	width: 2px;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	.line {
		height: 0;
	}
	.line-trigger-target {
		transition: height 0.5s ease-in-out;
		&.slow {
			transition: height 0.75s ease-in-out;
		}
		&.fast {
			transition: height 0.25s ease-in-out;
		}
	}
	.triggered {
		height: 100%;
	}
	&.white {
		// background-color: rgba(255, 255, 255, 0.2);
		.line {
			background-color: white;
		}
	}
	&.primary {
		// background-color: rgba(46, 87, 237, 0.2);
		.line {
			background-color: $primary;
		}
	}
	&.secondary {
		// background-color: rgba(46, 87, 237, 0.2);
		.line {
			background-color: $secondary;
		}
	}
	&.deep-thought-blue {
		// background-color: rgba(46, 87, 237, 0.2);
		.line {
			background-color: $deep-thought-blue;
		}
	}
}
