section.insights-grid {
	margin: 0;
	.container {
		position: relative;
		// padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
		.insights-grid-cards {
			display: grid;
			grid-template: 1fr / repeat(3, 1fr);
			gap: clamp(18px, 1.875vw, 30px); // 30px @ 1600px
			.insights-grid-card {
				opacity: 0;
				transition: opacity 1s ease;
			}
			&.live {
				.insights-grid-card {
					opacity: 1;
					// loop must be longer than expected number of each load
					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							transition-delay: #{$i * 150}ms;
						}
					}
				}
			}
			.insights-grid-card {
				position: relative;
				overflow: hidden;
				display: flex;
				align-items: flex-end;
				width: 100%;
				height: auto;
				aspect-ratio: 0.975;
				container-type: inline-size; // CQW @ 463px
				.image-wrapper {
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					left: 0;
					overflow: hidden;
					z-index: 1;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						transition: transform 0.3s ease-in-out, scale 0.3s ease-out;
					}
				}
				.content-wrapper {
					position: relative;
					background-color: $primary;
					z-index: 2;
					width: auto;
					padding: 3.239cqw; // 15px @ 463px
					margin: 6.479cqw; // 30px @ 463px
					// display: flex;
					// flex-direction: column;
					display: grid;
					grid-template: auto 1fr / 1fr auto;
					gap: 4.319cqw 0; // 20px @ 463px
					transform: translateY(0);
					transition: transform 0.3s ease-in-out;
					h3 {
						grid-column: 1 / -1;
						grid-row: 1;
						color: white;
						transition: color 0.3s ease-out;
						font-size: 6.911cqw; // 32px @ 463px
						// white-space: nowrap;
						// overflow: hidden;
						// text-overflow: ellipsis;
						margin: 0;
						line-height: 1.05em;
					}
					.post-details {
						grid-column: 1;
						grid-row: 2;
						display: flex;
						align-items: center;
						color: white;
						font-size: 3.455cqw; // 16px @ 463px
						span {
							&:not(:first-child) {
								border-left: 1px solid white;
								padding-left: 8px;
								margin-left: 8px;
							}
						}
					}
					.arrow-icon {
						grid-column: 2;
						grid-row: 2;
						display: flex;
						justify-content: flex-end;
						svg {
							height: 4.319cqw; // 20px @ 463px
						}
					}
				}
				&:hover {
					.image-wrapper {
						img {
							scale: 1.025;
						}
					}
					.content-wrapper {
						h3 {
							color: $secondary;
						}
					}
				}
			}
			@media screen and (min-width: map-get($min-breakpoints, "medium")) and (max-width: map-get($breakpoints, "large")) {
				grid-template: 1fr / 1fr 1fr;
				// .insights-grid-card {
				// 	&:nth-child(3n) {
				// 		grid-column: span 2;
				// 	}
				// }
			}
			@media screen and (max-width: map-get($breakpoints, "medium")) {
				grid-template: 1fr / 1fr;
			}
		}
		.insights-grid-cards + .insights-grid-cards {
			padding-top: clamp(18px, 1.875vw, 30px); // 30px @ 1600px
		}
		.button-container {
			align-items: center;
			justify-content: center;
			padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px;
			padding-bottom: 20px;
		}
	}
	.line-wrapper {
		position: relative;
		height: clamp(80px, 10vw, 160px); // 160px @ 1600px
		.line-track {
			&.line-one {
				left: 50%;
			}
		}
	}
}
