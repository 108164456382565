@font-face {
    font-family: "icons";
    src: url("../fonts/icons.eot?41154004");
    src: url("../fonts/icons.eot?41154004#iefix") format("embedded-opentype"),
        url("../fonts/icons.woff2?41154004") format("woff2"), url("../fonts/icons.woff?41154004") format("woff"),
        url("../fonts/icons.ttf?41154004") format("truetype"), url("../fonts/icons.svg?41154004#icons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-right-open:before {
    content: "\e800";
} /* '' */
.icon-down-open-big:before {
    content: "\e801";
} /* '' */
.icon-arrow-button:before {
    content: "\e802";
} /* '' */
.icon-up-open-big:before {
    content: "\e803";
} /* '' */
.icon-down-open:before {
    content: "\e804";
} /* '' */
.icon-up-open:before {
    content: "\e805";
} /* '' */
.icon-down-open-mini:before {
    content: "\e806";
} /* '' */
.icon-up-open-mini:before {
    content: "\e807";
} /* '' */
.icon-right-dir:before {
    content: "\e808";
} /* '' */
.icon-twitter:before {
    content: "\f099";
} /* '' */
.icon-facebook:before {
    content: "\f09a";
} /* '' */
.icon-linkedin:before {
    content: "\f0e1";
} /* '' */
.icon-youtube-play:before {
    content: "\f16a";
} /* '' */
.icon-instagram:before {
    content: "\f16d";
} /* '' */
.icon-whatsapp:before {
    content: "\f232";
} /* '' */
.icon-facebook-squared:before {
    content: "\f308";
} /* '' */
.icon-linkedin-squared:before {
    content: "\f30c";
} /* '' */
