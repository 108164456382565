// MARGIN AND PADDING
$sizes: (
	"1": 10px,
	"2": 20px,
	"3": 30px,
	"4": 40px,
);
@each $name, $value in $sizes {
	.pt-#{$name} {
		padding-top: $value !important;
	}
	.pb-#{$name} {
		padding-bottom: $value !important;
	}
	.mt-#{$name} {
		margin-top: $value !important;
	}
	.mb-#{$name} {
		margin-bottom: $value !important;
	}
}

.alt-background + .alt-background {
	margin-top: 0 !important;
	.container {
		&:first-child {
			padding-top: clamp(15px, 1.875vw, 30px);
		}
	}
}
