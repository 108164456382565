section.filter-controls {
	margin-top: 0;
	.container {
		position: relative;
		padding-left: $spaceFromLine;
		padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
		// .filter-controls {
		ul {
			display: flex;
			justify-content: flex-start;
			margin: 0 !important;
			padding: 0;
			-webkit-margin-before: 0 !important;
			margin-block-start: 0 !important;
			-webkit-margin-after: 0 !important;
			margin-block-end: 0 !important;
			-webkit-padding-start: 0 !important;
			padding-inline-start: 0 !important;
			display: flex !important;
			align-items: center;
			font-size: clamp(18px, 1.375vw, 22px); // 22px @ 1600px;
			li {
				padding: 0;
				&.sf-level-0 {
					label {
						white-space: nowrap;
						cursor: pointer;
						color: $deep-thought-blue;
						transition: color 0.2s ease-in-out;
						font-weight: 500;
						&:hover {
							color: $primary;
						}
					}
					input {
						display: none;
					}
					&.sf-option-active {
						label {
							color: $primary;
							// font-weight: 600;
						}
					}
				}
			}
			@media screen and (min-width: map-get($min-breakpoints, "large")) {
				li.sf-level-0 {
					&:not(:first-child) {
						label {
							border-left: 1px solid $primary;
							padding: 0 0 0 0.4em;
							margin: 0 0 0 0.4em;
						}
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "large")) {
				flex-direction: column;
				align-items: flex-start;
				gap: 10px;
				li.sf-level-0 {
					&:not(.sf-option-active) {
						label {
							color: $primary;
							opacity: 0.6;
						}
					}
				}
			}
		}
		// }
	}
}

section.banner--work + section.filter-controls .container {
	padding-bottom: clamp(60px, 10vw, 160px); // 160px @ 1600px
}
