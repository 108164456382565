section.insight-post {
	margin: 0;
	.container {
		padding-top: clamp(150px, 25vw, 300px); // 150px @ 600px
		max-width: 1200px;
		.upper-button {
			padding: 0 0 30px 0;
		}
		.post-content {
			position: relative;
			padding-left: $spaceFromLine;
			h1 {
				font-size: clamp(38px, 3.75vw, 60px); // 60px @ 1600px
				color: $primary;
				margin: 0 0 0.8em 0;
			}
			.image-wrapper {
				position: relative;
				overflow: hidden;
				width: 100%;
				height: auto;
				aspect-ratio: 2.45;
			}
			.post-details {
				padding: 20px 0 0 0;
				display: flex;
				align-items: center;
				color: $primary;
				font-size: 20px;
				span {
					display: block;
					&:not(:first-child) {
						border-left: 1px solid $primary;
						padding-left: 12px;
						margin-left: 12px;
					}
				}
			}
			.wysiwyg {
				overflow: hidden;
				padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: $primary;
					b {
						font-weight: inherit;
					}
				}
				h2 {
					font-size: clamp(25px, 2.375vw, 38px); // 38px @ 1600px
					color: $primary;
					margin-bottom: 0.4em;
				}
				h3 {
					font-size: clamp(20px, 2vw, 32px); // 32px @ 1600px
					color: $primary;
					margin-bottom: 0.4em;
				}
				a {
					color: $primary;
					font-weight: inherit;
					&:hover {
						text-decoration: underline;
					}
				}
				ul,
				ol {
					padding-inline-start: 1.4em;
					margin-block-end: 1em;
				}
				p,
				li {
					color: $deep-thought-blue;
					font-size: clamp(18px, 2vw, 20px); // 24px @ 1200px
				}
				p {
					margin-bottom: 1em;
				}
				> *:last-child {
					margin-bottom: 0;
				}
				li + li {
					margin-top: 0.2em;
				}
				table,
				ul,
				ol,
				p {
					+ h2,
					+ h3,
					+ h4,
					+ h5,
					+ h6 {
						margin-top: 1.5em;
					}
				}
				table {
					margin: 36px 0;
				}
				img {
					display: block;
					max-width: 100%;
					width: auto;
					height: auto;
					margin-right: auto;
					margin-left: auto;
				}
			}
		}
		.post-footer {
			margin-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
			.post-footer-columns {
				display: grid;
				grid-template: 1fr / 1fr auto;
				justify-content: space-between;
				align-items: start;
				.post-footer-column {
					padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
					h5 {
						font-size: clamp(20px, 2vw, 32px); // 32px @ 1600px
						color: $primary;
						margin-bottom: 0;
					}
					.team-member-card {
						margin-top: 20px;
						width: clamp(200px, 42.5vw, 340px); // 340px @ 800px
					}
					&.column-one {
						position: relative;
						padding-left: $spaceFromLine;
					}
					&.column-two {
						display: flex;
						align-items: center;
						justify-content: flex-end;
						gap: 12px;
					}
				}
				@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
					grid-template: 1fr / 1fr;
					gap: 40px;
					.post-footer-column {
						&.column-two {
							// display: block;
							justify-content: flex-start;
						}
					}
				}
				@media screen and (max-width: map-get($breakpoints, "small")) {
					.post-footer-column {
						.team-member-card {
							width: 100%;
						}
					}
				}
			}
			.social-icons {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 6px;
				a {
					background-color: $primary;
					width: 30px;
					height: 30px;
					border-radius: 15px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 15px;
					line-height: 0.8em;
					transition: background-color 0.3s ease-in-out;
					span {
						display: block;
						svg {
							fill: white;
						}
					}
					&:hover {
						background-color: $deep-thought-blue;
					}
				}
				@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
					justify-content: flex-start;
				}
			}
		}
	}
}
