.block {
	position: relative;
	overflow: hidden;
	&--lg {
		min-height: 92vh;
	}
	&--md {
		min-height: 72vh;
	}
	.columns {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		padding: 100px 0;
	}
	.content-container {
		text-align: center;
		display: inline-block;
	}
}
