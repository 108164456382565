section.into-focus {
	// position: relative;
	background-color: $primary; // temporary
	// overflow: hidden;
	.container {
		// aspect-ratio: 1.5; // 1320/880
		// min-height: 500px;
		position: relative;
		display: flex;
		align-items: flex-end;
		padding-top: clamp(80px, 10vw, 160px); // 80px @ 1600px
		overflow: hidden;
		.background-image {
			background-color: $deep-thought-blue;
			position: absolute;
			top: -30px;
			right: -30px;
			bottom: -30px;
			left: -30px;
			overflow: hidden;
			z-index: 1;
			.img-center {
				object-fit: cover;
				// filter: blur(10px);
			}
		}
		&-wrapper {
			&--inner {
				position: relative;
				z-index: 2;
				h2 {
					font-size: clamp(52px, 6.125vw, 98px); // 98px @ 1600px
					color: white;
					margin: 0;
				}
				@media screen and (max-width: map-get($breakpoints,"tablet-portrait")) {
					width: 100% !important;
					h2 {
						font-size: clamp(52px, 13.333vw, 75px); // 52px @ 390px
						font-size: clamp(52px, 9.375vw, 75px); // 75px @ 800px
					}
				}
			}
		}
	}
	&.home {
		.container {
			// aspect-ratio: 1.6;
			min-height: clamp(600px, 50vw, 50vw);
			max-height: 880px;
			&-wrapper {
				&--inner {
					width: 60%;
					padding: 0 $spaceFromLine clamp(30px, 3.75vw, 60px) 0; // 60px @ 1600px
				}
			}
		}
	}
	&:not(.home) {
		.container {
			// aspect-ratio: 2;
			min-height: clamp(600px, 45vw, 730px);
			// max-height: 730px;
			&-wrapper {
				&--inner {
					width: 50%;
					padding: 0 $spaceFromLine clamp(60px, 7.5vw, 120px) 0; // 120px @ 1600px
				}
			}
		}
	}
	&.about {
		.container {
			&-wrapper {
				&--inner {
					padding: 0 $spaceFromLine clamp(40px, 5vw, 80px) 0; // 80px @ 1600px
					.button-container {
						padding-top: clamp(20px, 2.5vw, 40px); // 40px @ 1600px
					}
				}
			}
		}
	}
	.container--home {
		padding: 0 0 clamp(40px, 5vw, 80px) 0; // 80px @ 1600px
		&--inner {
			position: relative;
			width: 60%;
			padding: clamp(30px, 3.75vw, 60px) $spaceFromLine 0 0; // 60px @ 1600px
			.textarea {
				color: white;
				p {
					font-size: clamp(18px, 2vw, 32px); // @ 1600px
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.button-wrapper {
				.button {
					margin-top: 1em;
				}
			}
			@media screen and (max-width: map-get($breakpoints,"tablet-portrait")) {
				width: 100%;
			}
		}
	}
	.line-track {
		z-index: 3;
		&.line-one--home {
			top: 0;
			left: unset;
			right: 0%;
		}
		&.line-two--home {
			top: 0;
			left: unset;
			right: 0%;
		}
		&.line-one--default {
			top: 0;
			bottom: clamp(60px, 7.5vw, 120px); // to match padding-bottom for text
			left: 50%;
			@media screen and (max-width: map-get($breakpoints,"tablet-portrait")) {
				// left: $mobileGutter;
				display: none;
			}
		}
	}
}
