.team-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 50px;
	margin: 0 auto;
	.team-card {
		display: block;
		width: 235px;
		&.has-bio {
			&:hover {
				cursor: pointer;
			}
		}
		.photo-wrapper {
			width: 235px;
			height: 295px;
			overflow: hidden;
			border-bottom: 7px solid $primary;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		h4 {
			color: white;
			font-size: 22px;
			padding: 10px 0;
			border-bottom: 1px solid #b9b5b5;
			margin: 0 0 10px 0;
		}
		p {
			color: white;
		}
		.popup-content {
			display: none;
		}
	}
}
