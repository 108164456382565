.section {
    &--wrapper {
        position: relative;
        z-index: 0;

        &__background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
    }
}

.sunrise {
    padding-bottom: 240px;
    z-index: 2;
    &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        right: 0;
        width: 100%;
        height: 34vw;
        // min-height: 400px;
        background-image: url(../images/tbg.svg);
        z-index: -1;
        background-size: 100%;
        background-position: bottom;
        // background-position-x: -50px;
    }

    @media (max-width: 800px) {
        &:after {
            height: 300px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;
            bottom: -2px;
        }
    }
    @media (max-width: 600px) {
        padding-bottom: 0px;
    }
}

.sunrise-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
    .top,
    .mid,
    .bot {
        position: absolute;
        top: 0vw;
        left: 0;
        width: 100%;
        padding-bottom: 80vw;
        border-radius: 100%;
        width: 160%;
        left: -30%;
        transition: top 0.2s ease-out;
        will-change: transform;
    }
    .bot {
        background-image: linear-gradient(#ffce00, #ffce04);
    }
    .mid {
        background-color: #ff6c00;
        opacity: 0.48;
    }
    .top {
        background-color: #ff6c00;
        opacity: 0.3;
    }
}
