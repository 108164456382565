/* ---- FLEX ---- */
.flex {
    display: flex;
}

@each $dir in row row-reverse column column-reverse {
    .flex-#{$dir} {
        flex-direction: #{$dir};

        @each $size, $width in $breakpoints {
            @media screen and (max-width: $width) {
                &--max-#{$size} {
                    flex-direction: #{$dir};
                }
            }
            @media screen and (min-width: $width) {
                &--min-#{$size} {
                    flex-direction: #{$dir};
                }
            }
        }
    }
}

@each $wrap in nowrap wrap wrap-reverse {
    .flex-#{$wrap} {
        flex-wrap: #{$wrap};

        @each $size, $width in $breakpoints {
            @media screen and (max-width: $width) {
                &--max-#{$size} {
                    flex-wrap: #{$wrap};
                }
            }
            @media screen and (min-width: $width) {
                &--min-#{$size} {
                    flex-wrap: #{$wrap};
                }
            }
        }
    }
}

$justify: (
    "left": flex-start,
    "right": flex-end,
    "center": center,
    "between": space-between,
    "around": space-around,
    "evenly": space-evenly,
);

@each $class, $value in $justify {
    .align-#{$class} {
        justify-content: #{$value};
    }
}

$align: (
    "top": flex-start,
    "bottom": flex-end,
    "middle": center,
    "baseline": baseline,
    "stretch": stretch,
);

@each $class, $value in $align {
    .align-#{$class} {
        align-items: #{$value};
    }
}