section.image-panel {
	margin: 0;
	.container {
		padding: clamp(40px, 5vw, 80px) 0; // 80px @ 1600px
		.image-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			min-height: 25vw;
			img {
				object-fit: scale-down;
			}
		}
	}
}
