.grid {
    display: flex;
    flex-wrap: wrap;
    &.col-1 .grid__item {
        flex: 100%;
    }
    &.col-2 .grid__item {
        flex: 0 1 50%;
    }
    &.col-3 {
        .grid__item {
        flex: 0 1 30.9231%;
    }
}
    &.col-4 {
        gap:3.33%;
        .grid__item {
        flex: 0 1 22.5%;
    }
}
    &.col-5 .grid__item {
        flex: 0 1 20%;
    }
    &.col-6 .grid__item {
        flex: 0 1 16.6666%;
    }
}