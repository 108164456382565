/* --- POPUPS --- */

.popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 60;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup .popup-background-overlay{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(27, 43, 59, 0.4);
    opacity: 0;
}
.popup.show .popup-background-overlay{
    opacity: 1;
}
.popup .popup-wrapper{
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    width: 94%;
    opacity: 0;
    margin: auto;
    max-width: 900px;
    transform: translateY(40px);
    transition: all 0.4s ease-in-out;
    max-height: 96%;
    overflow-y: auto;
    border-radius: 0px;
    will-change: transform;
}
.popup.show .popup-wrapper{
    opacity: 1;
}
.popup .popup-container{
    height: 100%;
    width: 100%;
    background-color: white;
    padding: 0;
    border-radius: 0px;
    box-sizing: border-box;
    min-height: 325px;
    display: flex;
    justify-content: center;
}
.popup .popup-container .content-container{
    position: relative;
    z-index: 2;
    padding: 30px 40px;
}
.popup .close-button{
    position: absolute;
    top: 8px;
    right: 8px;
    height: 26px;
    width: 26px;
    z-index: 10;
    cursor: pointer;
}
.popup .close-button:before, 
.popup .close-button:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 3px;
    top: 3px;
    right: 11px;
    background-color: white;
    transition: all 0.3s ease-out;
}
.popup .close-button:before{
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.popup .close-button:after{
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.close-button span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 100%;
    transform: scale(0);
    z-index: -1;
    transition: all 0.3s ease-out;
}
.popup .close-button:hover:before,
.popup .close-button:hover:after{
    height: 82%;
    background-color: $primary;
}
.popup .close-button:hover span {
    transform: scale(1.3);
}
.popup .close-button p{
    position: absolute;
    top: 6px;
    left: -40px;
    font-size: 14px;
    transition: all 0.25s ease-in-out;
    color: white;
}
.popup .close-button:hover p{
    left: -48px;
}

.popup.show{
    visibility: visible;
    opacity: 1;
}
.popup.show .popup-wrapper{
    transform: translateY(0);
}

@media screen and (max-width: 600px){
    .popup{

    }
    .popup .popup-wrapper{

    }
}