.case-study-cards {
	display: grid;
	grid-template: 1fr / repeat(3, 1fr);
	gap: 30px;
	.case-study-card {
		opacity: 0;
		transition: opacity 1s ease;
	}
	&.live {
		.case-study-card {
			opacity: 1;
			// loop must be longer than expected number of each load
			@for $i from 1 through 20 {
				&:nth-child(#{$i}) {
					transition-delay: #{$i * 150}ms;
				}
			}
		}
	}
	.case-study-card {
		display: flex;
		align-items: flex-end;
		position: relative;
		aspect-ratio: 0.676;
		container-type: inline-size;
		.image-wrapper {
			position: absolute;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 1;
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				overflow: hidden;
				object-fit: cover;
				transform: scale(1);
				transition: transform 0.3s ease-out;
			}
		}
		.card-inner {
			background-color: $primary;
			padding: 5cqw 4cqw 3cqw 4cqw;
			position: relative;
			width: 100%;
			margin: 10cqw;
			min-height: 80cqw;
			z-index: 2;
			display: grid;
			grid-template: auto auto 1fr auto / 1fr;
			.label {
				font-size: 14px;
				display: block;
				color: white;
				margin-bottom: 2cqw;
			}
			h3 {
				font-size: 12.608cqw; // 58px @ 460px
				color: white;
				margin-bottom: 0.1em;
				transition: color 0.3s ease-in-out;
			}
			p {
				font-size: 6.956cqw; // 32px @ 460px
				color: white;
				margin-bottom: 0;
			}
			.arrow {
				justify-self: end;
				// font-size: 4.347cqw; // 20px @ 460px
				font-size: 4.782cqw; // 22px @ 460px
				// font-size: 5.217cqw; // 24px @ 460px
				display: flex;
				align-items: center;
				gap: 0.5em;
				span {
					color: $secondary;
				}
				svg {
					height: 4cqw;
				}
			}
		}
		&:hover {
			.image-wrapper {
				img {
					transition: transform 0.9s ease-out;
					transform: scale(1.05);
				}
			}
			.card-inner {
				h3 {
					color: $secondary;
				}
			}
		}
		@media screen and (min-width: map-get($min-breakpoints, "large")) {
			&:nth-child(3n - 2) {
				top: min(5.625vw, 90px); // 90px @ 1600px;
			}
			&:nth-child(3n) {
				top: max(-90px, -5.625vw);
			}
			&:nth-child(odd) {
				.card-inner {
					background-color: $deep-thought-blue;
				}
			}
		}
		@media screen and (min-width: map-get($min-breakpoints, "medium")) and (max-width: map-get($breakpoints, "large")) {
			&:nth-child(2n - 1) {
				top: 5.625vw; // 90px @ 1600px;
			}
			&:nth-child(4n),
			&:nth-child(4n - 3) {
				.card-inner {
					background-color: $deep-thought-blue;
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "medium")) {
			&:nth-child(odd) {
				.card-inner {
					background-color: $deep-thought-blue;
				}
			}
		}
	}
	@media screen and (max-width: map-get($breakpoints, "large")) {
		grid-template: 1fr / repeat(2, 1fr);
		// .case-study-card:nth-child(n + 3) {
		// 	display: none;
		// }
	}
	@media screen and (max-width: map-get($breakpoints, "medium")) {
		grid-template: 1fr / 1fr;
		.case-study-card {
			top: 0 !important;
			.card-inner {
				grid-template: auto 1fr auto / 1fr;
				min-height: 0;
				.label {
					display: none;
				}
				h3 {
					margin-bottom: 0.45em;
				}
				p {
					margin-bottom: 0.6em;
				}
			}
		}
	}
}
.case-study-cards + .case-study-cards {
	padding-top: 30px;
}
