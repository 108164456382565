.two-column-swiper {
	height: 100%;
	width: 100%;
	.swiper-pagination.swiper-pagination-bullets {
		z-index: 2;
		bottom: 25px;
		.swiper-pagination-bullet {
			width: 15px;
			height: 15px;
			background-color: white;
			opacity: 0.4;
		}
		.swiper-pagination-bullet-active {
			opacity: 1;
		}
	}
}
