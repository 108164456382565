section.vacancy-cards {
	margin-top: 0;
	padding-bottom: clamp(30px, 3.75vw, 60px); // 80px @ 1600px
	.container {
		padding-top: clamp(60px, 10vw, 160px); // 160px @ 1600px
		padding-left: $spaceFromLine;
		position: relative;
		h2 {
			width: 50%;
			color: $primary;
			// font-size smaller on mobile to fit "opportunities"
			font-size: clamp(45px, 6.125vw, 98px); // 98px @ 1600px
			margin-bottom: 0;
		}
		h3.sorry {
			color: $primary;
			font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
			margin: 1em 0 0 0;
		}
		.vacancy-cards-grid {
			.vacancy-card {
				max-width: 960px;
				padding-top: clamp(40px, 5vw, 80px); // 80px @ 1600px
				h3 {
					color: $primary;
					font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600px
					margin-bottom: 0.2em;
				}
				.overview {
					padding: calc($spaceFromLine / 2) 0;
					display: grid;
					grid-template: 1fr / auto 1fr;
					gap: $spaceFromLine;
					h4 {
						color: $primary;
						font-size: clamp(16px, 3.913cqw, 18px); // 18px @ 460px
						line-height: 1.15em;
						// margin: 0;
					}
					.textarea {
						p {
							font-size: clamp(16px, 3.913cqw, 18px); // 18px @ 460px
							line-height: 1.15em;
							&:last-child {
								margin: 0;
							}
						}
					}
				}
			}
			@media screen and (max-width: map-get($breakpoints, "medium")) {
				.vacancy-card {
					.overview {
						display: block;
					}
				}
			}
		}
	}
}
section.vacancy-cards + section.light-background {
	margin-top: 0;
}
