section.brand-messaging-carousel {
	font-size: clamp(42px, 10.625vw, 170px); // 170px @ 1600px
	.container {
		background-color: $deep-thought-blue; // temporary?
		// aspect-ratio: 1.39; // 1600 / 1150
		width: 100%;
		height: 71.875vw; // 1150px @ 1600px
		max-height: 1150px;
		min-height: 500px;
		position: relative;
		h2 {
			width: auto;
			// font-size: clamp(42px, 10.769vw, 170px); // 42px @ 390px
			font-size: inherit;
			color: white;
			margin: 0;
			.brand-message-target {
				display: flex;
				position: absolute;
				left: 1.475em;
				top: 0;
				div {
					display: inline-block;
				}
			}
		}
		&--over {
			pointer-events: none;
			z-index: 2;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2.6em;
			&--inner {
				position: relative;
				display: flex;
				justify-content: flex-start;
				gap: 0 0.15em;
				height: 100%;
				padding: 0;
				// padding-bottom: clamp(65px, 15vw, 240px); // 240px @ 1600px
				// padding-bottom: clamp(65px, 16.666vw, 240px); // 65px @ 390px
				.button-wrapper {
					display: flex;
					margin-left: 0.275em;
					height: 1.4em;
					align-items: flex-end;
					.button {
						pointer-events: all;
					}
				}
			}
		}
		.brand-messages,
		.swiper {
			min-width: 100%;
			width: auto;
			height: 100%;
			overflow: hidden;
		}
		.brand-messages {
			// overflow-x: auto;
			display: flex;
			.brand-message {
				flex-shrink: 0;
				height: 100%;
				width: 100%;
				.image-wrapper {
					position: relative;
					width: 100%;
					height: calc(100% + 2px);
					top: -1px;
					overflow: hidden;
					z-index: 1;
					.img-center {
						object-fit: cover;
					}
				}
				&--over {
					pointer-events: none;
					z-index: 2;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 2.6em;
					&--inner {
						position: relative;
						display: flex;
						justify-content: flex-start;
						gap: 0.15em;
						height: 100%;
						padding: 0 0 0 1.45em;
						h2 {
							opacity: 0;
							transition: opacity 0.75s 1s ease-in-out;
						}
					}
				}
				&.swiper-slide-active {
					h2 {
						opacity: 1 !important;
					}
				}
			}
		}
	}
	.line-track {
		z-index: 3;
		&.line-one {
			top: 0.1em;
			left: 1.325em;
		}
		&.line-two {
			left: 50%;
			bottom: 40%;
		}
		&.line-three {
			top: 20%;
			bottom: 40%;
			// left: calc(80% - 80px);
			left: unset;
			right: calc($mobileGutter + 80px);
			.line {
				width: 1px;
				transition-delay: 0.2s;
			}
		}
		&.line-four {
			bottom: 30%;
			left: unset;
			right: $mobileGutter;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		.container {
			h2 {
				.brand-message-target {
					left: 1.4em;
				}
			}
			&--over {
				height: 1.8em;
				min-height: 85px;
				&--inner {
					display: grid;
					grid-template: auto 1fr / auto;
					.button-wrapper {
						height: 0.3em;
						align-self: center;
						justify-self: center;
						// display: block !important;
						position: relative;
						// padding-bottom: clamp(15px, 3.846vw, 50px);
						margin-left: 0.05em;
					}
				}
			}
			.brand-messages {
				.brand-message {
					&--over {
						height: 1.8em;
						min-height: 85px;
					}
				}
			}
		}
		.line-track {
			&.line-two {
				bottom: 2.1em;
			}
			&.line-one,
			&.line-three,
			&.line-four {
				display: none;
			}
		}
	}
}
