section.newsletter-signup {
	margin-top: clamp(120px, 15vw, 240px); // 240px @ 1600px
	.container {
		.content-wrapper {
			.layout-grid {
				display: grid;
				grid-template: 1fr / auto 1fr;
				.layout-spacer {
					font-size: clamp(42px, 10.625vw, 170px); // 170px @ 1600px
					width: 1.325em;
				}
				.layout-content {
					width: clamp(350px, 50vw, 800px); // 900px @ 1600px
				}
			}
			h2 {
				// this font-size is necessary to align with Latest Insights line
				font-size: clamp(38px, 3.75vw, 60px); // 60px @ 1600px;
				color: $primary;
			}
			h3 {
				font-size: clamp(25px, 2vw, 32px); // 32px @ 1600px
				color: $deep-thought-blue;
				line-height: 1.15em;
			}
			.line-wrapper {
				// width: clamp(350px, 28.125vw, 460px); // 460px @ 1600px
				// width: clamp(350px, 50vw, 800px); // 900px @ 1600px
				position: relative;
				padding-left: $spaceFromLine;
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
			}
			@media screen and (max-width: map-get($breakpoints,"large")) {
				padding-bottom: clamp(40px, 5vw, 80px); // 80px @ 1600px
				.layout-grid {
					display: block;
					.layout-spacer {
						display: none;
					}
					.layout-content {
						width: 75vw;
					}
				}
				.line-wrapper {
					padding-bottom: 0;
				}
			}
			@media screen and (max-width: map-get($breakpoints,"tablet-portrait")) {
				.layout-grid {
					display: block;
					.layout-content {
						width: 100%;
					}
				}
			}
		}
	}
	.line-track {
		&.line-one {
			// left: 1.325em;
		}
		// @media screen and (max-width: map-get($breakpoints,"large")) {
		// 	&.line-one {
		// 		display: none;
		// 	}
		// }
	}
	&.resources-page {
		.container {
			@media screen and (max-width: map-get($breakpoints, "large")) {
				padding-bottom: clamp(80px, 10vw, 160px); // 160px @ 1600px
			}
			// .content-wrapper {
			// 	.layout-grid {
			// 		.layout-content {
			// 			.line-wrapper {
			// 			}
			// 		}
			// 	}
			// }
		}
	}
	&.insights-page {
		margin-top: 0;
		.container {
			padding-top: clamp(80px, 8.75vw, 140px); // 140px @ 1600px
			padding-bottom: clamp(100px, 11.25vw, 180px); // 180px @ 1600px
			.content-wrapper {
				.layout-grid {
					display: block;
					// grid-template: 1fr / 1fr;
					// justify-content: center;
					.layout-spacer {
						display: none;
					}
					.layout-content {
						margin: 0 auto;
					}
				}
				.line-wrapper {
					padding-bottom: 0;
				}
			}
		}
	}
}
