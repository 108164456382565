// *{
//     outline: dashed green 1px!important;
// }
// --- COLOURS
$primary: #2e57ed; // curious-blue
$secondary: #00ffff; // exhilirating-blue
$body-background: white;
$fullpagemenu-background: $primary;
$deep-thought-blue: #0a1337;

.bg-s {
	background-color: $secondary;
}

// TYPOGRAPHY COLOURS
$header-colour: $deep-thought-blue;
$header-light-colour: white;
$sub-header-colour: $deep-thought-blue;
$sub-header-light-colour: $deep-thought-blue;
$paragraph-colour: $deep-thought-blue;
$paragraph-light-colour: white;
$paragraph-dark-colour: $deep-thought-blue;
$paragraph-anchor-colour: $secondary;
$anchor-hover-colour: $primary;

// HEADER
$sub-menu-background: white;
$sub-menu-text: $secondary;
$sub-menu-text-hover: $primary;

// FOOTER
$footer-background: $deep-thought-blue;
$footer-header: white;
$footer-socket-anchor: white;
$footer-socket-anchor-hover: $secondary;

// BUTTONS
$button-text-colour: white;
$button-colour: #38becb;
$button-border-colour: #38becb;
$button-hover-colour: #2f2272;
$button-hover-text-colour: $primary;
$button-secondary-colour: transparent;
$button-secondary-text-colour: $secondary;
$button-secondary-border-colour: #38becb;
$button-secondary-hover-colour: #2f2272;
$button-secondary-hover-text-colour: $primary;
$button-light-hover-colour: white;
$button-radius: 30px;

// SOCIAL
$social-background-color: #38becb;
$social-text-colour: white;
$social-background-hover-colour: #2f2272;
$social-text-hover-colour: white;

// ACCORDION
$accordion-title: $primary;
$accordion-title-open: $primary;
$accordion-toggle: $primary;
$accordion-border: #288fdf;
$accordion-border-open: #dddddd;

// --- SPACING
$space-small: 10px;
$space-medium: 20px;
$space-large: 40px;
$space-xlarge: 70px;

// --- MISCELLANEOUS
$large-image-radius: clamp(30px, 7.692vw, 100px);

// --- TYPOGRAPHY
$global-weight: 400 !default; // Regular
$global-lineheight: 1.3 !default;
$global-font-size: 16px !default;
$header-font-weight: 400 !default; // Medium
$header-lineheight: 1em !default;
$header-margin-bottom: 0.5em !default;
$sub-header-font-weight: 400 !default; // Medium
$sub-header-lineheight: 1.1em !default;
$paragraph-margin-bottom: 0.75em !default;

// --- TYPOGRAPHY FONTS
// Regular 400
// Medium 500
// Bold 700
@font-face {
	font-family: "Tomato Grotesk";
	src: url("../fonts/TomatoGrotesk-Regular.otf") format("opentype");
	font-weight: 100 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Tomato Grotesk Medium";
	src: url("../fonts/TomatoGrotesk-Medium.otf") format("opentype");
	font-weight: 500 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: "Tomato Grotesk Bold";
	src: url("../fonts/TomatoGrotesk-Bold.otf") format("opentype");
	font-weight: 700 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "StraplineFont";
	src: url("../fonts/HelveticaNeueLight.woff2") format("woff2"),
		url("../fonts/HelveticaNeueLight.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// --- TYPOGRAPHY FONTS

$default-font: "Tomato Grotesk", sans-serif;
$header-large-font: "Tomato Grotesk", sans-serif;
$header-small-font: "Tomato Grotesk", sans-serif;
$alternative-font: "Tomato Grotesk", sans-serif;
$button-font: "Tomato Grotesk", sans-serif;
$header-nav-font: "Tomato Grotesk", sans-serif;

$header-styles: (
	//All is the same as media (min-width: 0px)
	all:
		(
			"h1": (
				"font-size": 50px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $header-font-weight,
			),
			"h2": (
				"font-size": 40px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $header-font-weight,
			),
			"h3": (
				"font-size": 30px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $header-font-weight,
			),
			"h4": (
				"font-size": 25px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $sub-header-font-weight,
			),
			"h5": (
				"font-size": 20px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $sub-header-font-weight,
			),
			"h6": (
				"font-size": 16px,
				"margin-bottom": $header-margin-bottom,
				"line-height": $header-lineheight,
				"font-weight": $sub-header-font-weight,
			),
		),
	medium: (
		"h1": (
			"font-size": 75px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
		"h2": (
			"font-size": 56px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
		"h3": (
			"font-size": 45px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
		"h4": (
			"font-size": 32px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
		"h5": (
			"font-size": 24px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
		"h6": (
			"font-size": 18px,
			"margin-bottom": $header-margin-bottom,
			"line-height": $header-lineheight,
		),
	)
) !default;

.forminator-field-captcha {
	display: none !important;
}
form {
	input,
	select,
	textarea {
		font-family: $default-font !important;
		font-weight: 400;
	}
}

// --- MAX WIDTH CONTAINER SIZES
$containerSizes: (
	"max": (
		max-width: 2400px,
		width: 100%,
	),
	"xl": (
		max-width: 1845px,
		width: calc(100% - 90px),
	),
	"lg": (
		max-width: 1450px,
		width: 90.625%,
	),
	"md": (
		max-width: 1360px,
		width: 85%,
	),
	"sm": (
		max-width: 880px,
		width: 88.5%,
	),
);

// --- BREAKPOINTS
$breakpoints: (
	all: 0px,
	small: 450px,
	medium: 600px,
	tablet-portrait: 800px,
	large: 1024px,
	xlarge: 1250px,
);
// --- MIN-BREAKPOINTS
$min-breakpoints: (
	all: 1px,
	small: 451px,
	medium: 601px,
	tablet-portrait: 801px,
	large: 1025px,
	xlarge: 1251px,
);

// --- GLOBAL SPACING
$section-space: 80px;
