section.team-grid {
	margin: 0;
	.container {
		// padding-bottom: clamp(60px, 7.5vw, 120px); // 120px @ 1600px
		padding-bottom: clamp(40px, 5vw, 80px); // 40px @ 1600px
		.team-grid-cards {
			display: grid;
			grid-template: 1fr / repeat(4, 1fr);
			gap: clamp(10px, 1.875vw, 30px); // 30px @ 1600px
		}
		.button-wrapper {
			display: none;
			padding-top: 60px;
			.button-container {
				align-items: center;
				justify-content: center;
				.button {
					font-size: clamp(20px, 5.128vw, 30px); // 20px @ 390px;
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "xlarge")) {
			.team-grid-cards {
				grid-template: 1fr / repeat(3, 1fr);
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			.team-grid-cards {
				grid-template: 1fr / repeat(2, 1fr);
			}
			.button-wrapper {
				display: block;
			}
		}
		@media screen and (max-width: map-get($breakpoints, "small")) {
			.team-grid-cards {
				grid-template: 1fr / 1fr;
				gap: 10px;
			}
		}
	}
}

section.team-grid,
section.insight-post {
	.team-member-card {
		position: relative;
		width: 100%;
		height: auto;
		aspect-ratio: 0.75;
		overflow: hidden;
		padding: 0 20px;
		display: flex;
		align-items: flex-end;
		container-type: inline-size; // CQW @ 340px;
		.image-wrapper {
			position: absolute;
			top: 0;
			bottom: 17.647cqw; // 60px @ 304px
			right: 0;
			left: 0;
			overflow: hidden;
			z-index: 1;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform 0.3s ease-in-out;
			}
		}
		.content-wrapper {
			position: relative;
			background-color: $primary;
			z-index: 2;
			width: 100%;
			padding: 4.411cqw; // 15px @ 340px
			display: flex;
			flex-direction: column;
			gap: 2.941cqw; // 10px @ 340px
			transform: translateY(0);
			transition: transform 0.3s ease-in-out;
			h3 {
				color: white;
				font-size: 8.823cqw; // 30px @ 340px
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
				line-height: 1.15em;
			}
			h4 {
				color: white;
				font-size: 5.882cqw; // 20px @ 340px
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
				line-height: 1.15em;
			}
			.arrow-icon {
				display: flex;
				justify-content: flex-end;
				svg {
					height: 5.882cqw; // 20px @ 340px
				}
			}
			.popup-content {
				display: none;
			}
		}
	}
	@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
		.team-member-card {
			&.hide-mobile {
				display: none;
			}
		}
	}
}

section.team-grid {
	.team-member-card {
		cursor: pointer;
		@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
			&:hover {
				.image-wrapper {
					img {
						transform: scale(1.025);
					}
				}
				.content-wrapper {
					transform: translateY(-5.882cqw); // 20px @ 340px
				}
			}
		}
	}
}

section.insight-post {
	.team-member-card {
		width: 100%;
		max-width: 340px;
		.arrow-icon {
			visibility: hidden;
		}
	}
}

#team-member-modal {
	display: flex;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
	&:not(.show) {
		opacity: 0;
		pointer-events: none;
		z-index: -1;
		height: 0;
		width: 0;
	}
	position: fixed;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgba(10, 19, 55, 0.85);
	z-index: 40; // below header navigation
	.team-member-card {
		background-color: white;
		width: 85%;
		max-width: 1200px;
		margin-top: 40px; // half of additional space added to vh for max-height
		position: relative;
		container-type: inline-size; // CQW @ 1200px
		.close-overlay-icon {
			background-color: $primary;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 34px;
			height: 34px;
			top: -16px;
			right: -16px;
			border-radius: 16px;
			// font-size: 24px;
			overflow: hidden;
			transition: transform 0.3s ease-in-out;
			svg {
				fill: $deep-thought-blue;
				height: 24px;
				width: auto;
				transition: fill 0.3s ease-in-out;
			}
			&:hover {
				transform: scale(1.1);
				svg {
					fill: white;
				}
			}
		}
		.team-member-columns {
			display: grid;
			grid-template: 1fr / auto 1fr;
			// gap: 20px;
			.image-column {
				position: relative;
				width: 41.666cqw; //500px @ 1200px
				height: 100%;
				min-height: 50cqw; // 600px @ 1200px
				overflow: hidden;
				.image-wrapper {
					top: -1px;
					right: -1px;
					bottom: -1px;
					left: -1px;
					position: absolute;
					overflow: hidden;
					z-index: 41;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				.linkedin {
					position: absolute;
					right: 20px;
					bottom: 20px;
					z-index: 42;
					.linkedin-button {
						background-color: $primary;
						color: white;
						display: block;
						padding: 5px 8px;
						line-height: 0;
						font-size: 24px;
						// transition: background-color 0.3s ease-in-out;
						// &:hover {
						// 	background-color: $deep-thought-blue;
						// }
					}
				}
			}
			.text-column {
				display: block;
				overflow: hidden;
				overflow-y: auto;
				margin: 5cqw 2.5cqw; // 60px @ 1200px
				padding: 0 2.5cqw;
				align-self: stretch;
				max-height: 40cqw; // (600px - 120px) @ 1200px
				h2 {
					color: $primary;
					font-size: clamp(24px, 2.666cqw, 32px); // 32px @ 1200px
					margin: 0 0 0.2em 0;
				}
				h3 {
					color: $deep-thought-blue;
					font-size: clamp(18px, 2.083cqw, 25px); // 25px @ 1200px
					margin: 0 0 0.8em 0;
				}
				h4 {
					font-size: clamp(18px, 1.666cqw, 20px); // 20px @ 1200px
					color: $primary;
					line-height: 1.15em;
					margin: 0.8em 0 0.4em 0;
					font-weight: 500;
				}
				p {
					font-size: clamp(16px, 1.666cqw, 20px); // 20px @ 1200px
					margin: 0;
				}
				p + p {
					margin-top: 0.8em;
				}
			}
			@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
				grid-template: 1fr / 1fr;
				.image-column {
					display: none;
				}
				.text-column {
					max-height: 90vh;
					margin: 32px 16px;
					padding: 0 16px;
				}
			}
		}
		.empty {
			display: none;
		}
	}
}
section.team-grid + section.light-background {
	margin-top: 0;
}
