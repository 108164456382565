section.service-types {
	margin-top: 0;
	padding: clamp(60px, 7.5vw, 120px) 0 clamp(80px, 10vw, 160px) 0; // VW @ 1600px
	position: relative;
	.container {
		position: relative;
		z-index: 2;
		.service-columns {
			display: grid;
			grid-template: 1fr / 1fr 1fr;
			align-items: stretch;
			gap: 90px;
			.service-column {
				h2 {
					font-size: clamp(25px, 2.812vw, 45px); // 45px @ 1600;
					color: $primary;
				}
				display: grid;
				grid-template: auto 1fr auto / 1fr;
				h3 {
					max-width: 100%;
					font-size: clamp(38px, 5vw, 80px); // 80px @ 1600px
					color: $primary;
					// margin-top: 0.6em;
					container-type: inline-size; // stops heading pushing .service-column out
				}
				.textarea {
					max-width: 500px;
					margin: 0 auto 0 0;
					p {
						font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
						color: $deep-thought-blue;
					}
				}
			}
		}
		@media screen and (min-width: map-get($min-breakpoints, "tablet-portrait")) {
			.service-columns {
				.service-column {
					h2 {
						text-align: right;
						margin-top: 0.2em;
					}
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			background-color: white;
			padding: clamp(40px, 2.5vw, 65px) 0; // 40px @ 390px
			.service-columns {
				display: block;
				.service-column {
					h3 {
						margin-top: 1em;
					}
				}
			}
		}
	}
	.line-track {
		&.line-one {
			left: 50%;
			z-index: 1;
		}
	}
}
