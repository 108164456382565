section.our-approach {
	margin-top: 0;
	background-color: $deep-thought-blue;
	.container {
		position: relative;
		&--inner {
			display: grid;
			grid-template: clamp(160px, 20vw, 320px) 1fr clamp(120px, 15vw, 240px) / 1fr 50%; // VW @ 1600px;
			align-items: start;
			.copy-wrapper {
				grid-row: 1 / 3;
				grid-column: 1;
				position: relative;
				padding-left: $spaceFromLine;
				.label-wrapper {
					height: clamp(160px, 15vw, 320px); // 320px @ 1600px;
					display: flex;
					align-items: center;
					h3 {
						font-size: clamp(25px, 2vw, 32px); // 32px @ 1600px
						color: $secondary;
						margin: 0;
					}
				}
				.button-container {
					.button {
						margin-top: 1em;
					}
				}
				.textarea {
					padding-right: clamp(60px, 10vw, 160px); // 160px @ 1600px
					p {
						font-size: clamp(18px, 1.5vw, 24px); // 24px @ 1600px
						color: white;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
			.header-wrapper {
				align-self: stretch;
				grid-row: 2 / 4;
				grid-column: 2;
				position: relative;
				padding-left: $spaceFromLine;
				h2 {
					font-size: clamp(38px, 6.125vw, 98px); // 98px @ 1600px;
					line-height: 1em;
					color: $secondary;
					padding-bottom: clamp(120px, 15vw, 240px); // 240px @ 1600px;
					font-weight: 400;
					margin: 0;
				}
			}
		}
		@media screen and (max-width: map-get($breakpoints, "tablet-portrait")) {
			&--inner {
				display: block;
				.copy-wrapper {
					.textarea {
						padding: 0;
					}
				}
				.header-wrapper {
					margin-top: clamp(80px, 10vw, 160px); // 160px @ 1600px;
				}
			}
		}
	}
}
