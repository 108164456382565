section.case-studies-grid {
	margin-top: 0;
	background-image: linear-gradient(
		to bottom,
		white,
		white clamp(120px, 15vw, 240px),
		$primary clamp(120px, 15vw, 240px)
	);
	.container {
		position: relative;
		padding-bottom: clamp(60px, 10vw, 160px); // 160px @ 1600px
		.button-container {
			padding-top: clamp(60px, 7.5vw, 120px); // 120px @ 1600px
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	@media screen and (max-width: map-get($breakpoints, "large")) {
		background-image: linear-gradient(
			to bottom,
			white,
			white clamp(150px, 19.531vw, 200px),
			$primary clamp(150px, 19.531vw, 200px)
		);
	}
	@media screen and (max-width: map-get($breakpoints, "medium")) {
		background-image: linear-gradient(
			to bottom,
			white,
			white clamp(680px, 175vw, 1200px),
			$primary clamp(680px, 175vw, 1200px)
		);
		.line-track {
			&.line-one {
				display: none;
			}
		}
	}
}
